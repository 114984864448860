import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, InputLabel, MenuItem, Popover, Select, Stack, TextField, Checkbox, CircularProgress, ListItemText } from "@mui/material";
import { BsChevronDown } from "react-icons/bs";
import { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CiCalendarDate } from "react-icons/ci";
import { MdDelete, MdTabUnselected } from "react-icons/md";
import APIList from "../../../api";
import { useDispatch, useSelector } from "react-redux";
import { getListItemDescription, getObjectById, isEmptyNullUndefined } from "../../utils/utils";
import { RiErrorWarningFill } from "react-icons/ri";
import { toast } from "react-toastify";
import DialogPopup from "../../common/Dialog";
import checkIcon from "../../../assets/images/popup-check.png";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";

const GoalsForm = () => {
    const {id} = useParams()
    const employeeDetails = useSelector((state) => state?.empData?.empData);

    const [vertualError, setVertualError] = useState('Q1 2024');
    const [vertualPayload, setVertualPayload] = useState(false);
    const [formData, setFormData] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [status, setStatus] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [dropdownLoader, setDropdownLoader] = useState(false)
    const [dropdownData, setDropdownData] = useState(null)
    const [formDataForContainer, setFormDataForContainer] = useState(false)

    useEffect(() => {

        const fetchData = async () => {
            try {
                if (!isEmptyNullUndefined(employeeDetails?.company?.id)) {
                    await getDropdownData();
                    await getFormData();
                    await getFormType();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData(); // Call the async function
        console.log('employeeDetails', employeeDetails?.company?.id)
        
    }, [employeeDetails]);

    const getDropdownData = async() => {
        try{
            setDropdownLoader(true);
            const response = await APIList.getMyTeamDropdowns({
                range: "My Team",                                                            // My Team Or My Org
                companyId: employeeDetails?.company?.id,
                managerId: employeeDetails?.id
            })
            if (response.status === 200) {
                setDropdownData(response?.data);
                setDropdownLoader(false);
            }
        } catch(error) {
            toast.error(
                <div style={{display:"flex", flexDirection:"row"}}>
                    <RiErrorWarningFill style={{ width: '2rem', height: '2rem' }} />
                    &nbsp;&nbsp;{error?.title}
                </div>
            );
            setDropdownLoader(false);
        }
    
    }

    const getFormType = async () => {
        setIsLoading(true);
        let payload = {

            companyId: employeeDetails.company.id,
            // formType:"base",
            // isContainer:false    

        }
        
        try {
          const response = await APIList.goalEmployeeFormType(payload);
          if (response.status === 200) {
            
            // setVertualError(tempvertualFormError);
            // setVertualPayload(response.data.assessmentFormDTO.sections);
            // setFormData(response.data);
            console.log('response', response)
          } else {
          }
        } catch (error) {
          
        }
        setIsLoading(false);
    };

    const mapFormData = (response, responseContainer) => {


        let tempResponseData = structuredClone(response.data)
        console.log('response in mapFormData', response)

        // return
        let tempgoalFormField = structuredClone(response.data.responseFormField)
        let tempcontainerFormField = structuredClone(responseContainer.data.responseFormField)
        // let tempcontainerFormField = structuredClone([])

        tempgoalFormField.sort((a, b) => a[0].parentSequenceNo - b[0].parentSequenceNo)

        tempgoalFormField = tempgoalFormField.map((e,i) => {
        console.log('e', e[0].isMultiField)
        if((e[0].isMultiField === false) || (e[0].isMultiField === "false")) {

            return e
        } else {
            e.sort((a,b) => a.sequenceNo - b.sequenceNo)
            return e
        }
        })


        tempcontainerFormField.sort((a, b) => a[0].parentSequenceNo - b[0].parentSequenceNo)

        tempcontainerFormField = tempcontainerFormField.map((e,i) => {
        if((e[0].isMultiField === false) || (e[0].isMultiField === "false")) {

                    if(e[0].type === 'container') {
                        e[0].container.sort((a,b) => a[0].parentSequenceNo - b[0].parentSequenceNo)
                        e[0].container = e[0].container.map((containerE, containerPI) => {
                            if((containerE[0].isMultiField === false) || (containerE[0].isMultiField === "false")) {
                            return containerE
                            } else {
                            containerE.sort((a,b) => a.sequenceNo - b.sequenceNo)
                            return containerE
                            }
                        })
                    }


            return e
        }
        })

        let tempgoalFormFieldForContainer = structuredClone(tempgoalFormField)

        tempcontainerFormField.forEach((e,i) => {
        tempgoalFormField.splice(e[0].parentSequenceNo, 0, e)

        tempgoalFormFieldForContainer.splice(e[0].parentSequenceNo, 0, [e])
        })

        console.log('tempgoalFormField', tempgoalFormField)

        // tempResponse.data.responseFormField = tempgoalFormField

        // setVertualPayload(tempResponse.data.responseFormField);
        // setFormData(tempResponse.data);

        tempResponseData.responseFormField = tempgoalFormField
        // setVertualPayload(tempgoalFormField);
        setVertualPayload(tempgoalFormFieldForContainer);
        console.log('----tempResponseData----', tempResponseData)
        setFormData(tempResponseData);

        setFormDataForContainer(tempgoalFormFieldForContainer);
    }

    const getFormData = async () => {
        setIsLoading(true);
        // let payload = {

        //     companyId: employeeDetails.company.id,
        //     formType:"BASE",
        //     // formType:'milesStone',
        //     isContainer:false,
        //     employeeId: employeeDetails.id,    
        //     // id: "ade40cf4-c9c8-4c7d-9c92-28035a43f160",
        //     status: 'DRAFT'

        // }
        let payloadBase = {
            companyId: employeeDetails.company.id,
            formType:"BASE",
            isContainer:false,
            employeeId: employeeDetails.id,    
            status: 'DRAFT'
        }
        let payloadContainer = {
            companyId: employeeDetails.company.id,
            formType:"container",
            isContainer:true,
            employeeId: employeeDetails.id,    
            status: 'DRAFT'
        }
        console.log('id', id)
        // if(!isEmptyNullUndefined(id)) {
        //     if(id === 'newform') {
        //         console.log('new form')
        //     } else {
        //         console.log('id form')
        //         payload.id = id
        //     }
        // }
        
        try {
          const responseBase = await APIList.goalEmployee_employee_form(payloadBase);
          if (responseBase.status === 200) {
            
            // setVertualError(tempvertualFormError);
            // mapFormData(response)
            // setVertualPayload(response.data.responseFormField);
            // setFormData(response.data);
            // console.log('response in api block', response)

            try{
                const responseContainer = await APIList.goalEmployee_employee_form(payloadContainer);
                if (responseContainer.status === 200) {
                    mapFormData(responseBase, responseContainer)
                } else {
                    mapFormData(responseBase, null)
                }
            } catch (error) {
                mapFormData(responseBase, null)
            }
          } else {
          }
        } catch (error) {
          
        }
        setIsLoading(false);
      };

      const handleChangeVertualPayload = (
        tempVertualPayload,
        event,
        inputType,
        tabIndex,
        rowPosition,
        TabElement
      ) => {

        console.log('++++++++++++++++++')

        console.log('tempVertualPayload', tempVertualPayload)
        console.log('event', event)
        console.log('inputType', inputType)
        console.log('tabIndex', tabIndex)
        console.log('rowPosition', rowPosition)
        console.log('TabElement', TabElement)

        console.log('--------------')

        if (inputType === "textField") {
            tempVertualPayload[tabIndex][rowPosition].response.stringResponse = event.target.value;
            // checkIsStringUnderLimit(event, tempVertualPayload[tabIndex], tabIndex, null, null, 'tab', tempvertualFormError)
        } else if(inputType === 'selectField') {
            if((TabElement[rowPosition].isSingleSelect === 'true') || (TabElement[rowPosition].isSingleSelect === true)) {
                let tempValue = []
                tempValue.push(event.target.value)
                tempVertualPayload[tabIndex][rowPosition].response.selectResponse = tempValue;
            } else {
                tempVertualPayload[tabIndex][rowPosition].response.selectResponse = event.target.value;
            }
            console.log('tempVertualPayload[tabIndex][rowPosition].response.selectResponse', tempVertualPayload[tabIndex][rowPosition].response)
        } else if (inputType === 'dateField') {
            tempVertualPayload[tabIndex][rowPosition].response.dateResponse = event;
        } else if (inputType === 'numberField') {
            tempVertualPayload[tabIndex][rowPosition].response.numberResponse = event.target.value;
        }
        // return 

        console.log('vertual payload', vertualPayload)

        setVertualPayload(() => tempVertualPayload)

      }

      const styleSubmit = () => {
        if(status === 'ACTIVE') {
          return ({
            pointerEvents: 'none',
            opacity: '50%',
            transition: 'opacity 0.3s',
            visibility: 'hidden',
          })
        } else {
          if(isSubmitDisabled || submitLoader) {
            return ({
              pointerEvents: 'none',
              opacity: '50%',
              transition: 'opacity 0.3s'
            })
          } else {
            return ({
              pointerEvents: 'auto',
              opacity: '100%',
              transition: 'opacity 0.3s'
            })
          }
        }
      }
    
      const styleSaveAsDraft = () => {
        if(status === 'ACTIVE') {
          return ({
            pointerEvents: 'none',
            opacity: '50%',
            transition: 'opacity 0.3s',
            visibility: 'hidden',
          })
        } else {
          if(submitLoader) {
            return ({
              pointerEvents: 'none',
              opacity: '50%',
              transition: 'opacity 0.3s'
            })
          } else {
            return ({
              pointerEvents: 'auto',
              opacity: '100%',
              transition: 'opacity 0.3s'
            })
          }
        }
      }

      const handleValidate = () => {
        let isValid = true;
        let tempFormData = structuredClone(formData);
        let tempvertualFormError = structuredClone(vertualError);
        let tempvertualFormPayload = structuredClone(vertualPayload);
    
        // check validation
    
        tempvertualFormPayload.forEach((tab, tabIndes) => {<></>})

        if (isValid) {
            setShowConfirmationPopup(true);
          } else {
            // set form error
            setVertualError(tempvertualFormError);
            toast.error(
              <div style={{ display: "flex", flexDirection: "row" }}>
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;{"Please enter all mandatory field"}
              </div>
            );
          }

      }

      const handleSubmitFeedback = () => {
        setShowConfirmationPopup(false);
        if (!isEmptyNullUndefined(formData.id)) {
          //  'update calls -------------'
        //   handleUpdate('ACTIVE');
        handleSubmit('ACTIVE');
        } else {
          // 'submit calls--------------'
          handleSubmit('ACTIVE');
        }
      }

      const handleSubmit = (status, submitType) => {
        
        let payload = {
            companyId: employeeDetails.company.id,
            formType:"BASE",
            isContainer:false,
            employeeId: employeeDetails.id,   
            responseFormField: vertualPayload,
            status:"DRAFT",

            // id: "ad6d186e-b972-4529-beb7-d36e1307b1ea",
        }

        if(!isEmptyNullUndefined(formData.id)) {
            payload.id = formData.id
        }
    
        setSubmitLoader(true);
        APIList.post_goalEmployee_employee_form(payload)
          .then((res) => {
            // if((status == 'ACTIVE') || (submitType == 'submitAsDraft')) {
            //   setOpenDialog(true);
            // } else {
            //   setFormData(() => res.data)
            // }
            // setSubmitLoader(false);
            // setStatus(res.data.status)
            // setStatusForPost(res.data.status)
          })
          .catch((err) => {
            toast.error(
              <div style={{ display: "flex", flexDirection: "row" }}>
                <RiErrorWarningFill style={{ width: "2rem", height: "2rem" }} />
                &nbsp;&nbsp;{err?.message}
              </div>
            );
            setSubmitLoader(false);
          });
        // setSubmitLoader(false);
        setIsSubmitDisabled(false)
      };

      const randerDynamicTileElement = (TileElement, tileIndex) => {
        console.log('TileElement', TileElement)
        if (TileElement && TileElement?.length > 0) {
            return <div key={tileIndex}>
                {((TileElement[0].isMultiField === true) || (TileElement[0].isMultiField === 'true')) ? (
                    

                    <div className="field-row">
                        <div className="row-half">
                            {(TileElement[0].type === "text") && (
                                <div className="text-field">

                                    <div className="field-label" >
                                        {TileElement[0].label}
                                    </div>
                                    <TextField
                                        name={TileElement[0].name}
                                        // value={vertualPayload[tabIndex][0].response.stringResponse}
                                        // onChange={(event) =>
                                        //     handleChangeVertualPayload(
                                        //         JSON.parse(JSON.stringify(vertualPayload)),
                                        //         event,
                                        //         "textField",
                                        //         tabIndex,
                                        //         0, //row position
                                        //     )
                                        // }
                                        multiline={TileElement[0].isMultiTextFieldRow ?? false}
                                        rows={TileElement[0].textFieldRowSize ?? 1}
                                        id="outlined-multiline-static"
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            width: '100%',
                                            mt: '6px',
                                            "& fieldset": {
                                                borderRadius: '8px',                              
                                                },
                                        }}
                                    />
                                </div>
                            )}
                            {(TileElement[0].type === "number") && (
                                <div className="number-field">

                                    <div className="field-label" >
                                        {TileElement[0].label}
                                    </div>
                                    <TextField
                                        name={TileElement[0].name}
                                        // value={vertualPayload[tabIndex][0].response.stringResponse}
                                        // onChange={(event) =>
                                        //     handleChangeVertualPayload(
                                        //         JSON.parse(JSON.stringify(vertualPayload)),
                                        //         event,
                                        //         "numberField",
                                        //         tabIndex,
                                        //         0, //row position
                                        //     )
                                        // }
                                        multiline={TileElement[0].isMultiTextFieldRow ?? false}
                                        rows={TileElement[0].textFieldRowSize ?? 1}
                                        id="outlined-multiline-static"
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            width: '100%',
                                            mt: '6px',
                                            "& fieldset": {
                                                borderRadius: '8px',                              
                                                },
                                        }}
                                    />
                                </div>
                            )}
                            {(TileElement[0].type === "select") && (
                                <div className="select-field">
                                
                                <div className="field-label" >
                                    {TileElement[0].label}
                                </div>
                                <div>
                                    <FormControl
                                    sx={{width: {md: '100%', sm: '100%'}}}
                                    >
                                    <Select
                                        // value={vertualPayload[tabIndex][0].response.selectResponse || []}
                                        // renderValue={(selected) =>
                                        //     {
                                        //         if((TileElement[1].isSelectMenuDynamic === 'true') || (TileElement[1].isSelectMenuDynamic === true)) {
                                        //             return getListItemDescription(selected, dropdownData?.[TileElement[1].selectMenuDynamicType], 'name')
                                        //         } else {
                                        //             return  selected + ", "
                                        //         }
                                        //     }
                                        // }
                                        name={TileElement[0].name}
                                        // onChange={(event) =>
                                        //     handleChangeVertualPayload(
                                        //         JSON.parse(JSON.stringify(vertualPayload)),
                                        //         event,
                                        //         "selectField",
                                        //         tabIndex,
                                        //         0, //row position
                                        //         TileElement
                                        //     )
                                        // }
                                        sx={{
                                            width: '100%',
                                            mt: '6px',
                                            "& fieldset": { 
                                                borderRadius: '8px',   
                                                
                                            },
                                        }}
                                        size="small"
                                        labelId='demo-simple-select-label'
                                        id='demo-simple-select'
                                        multiple={((TileElement[0].isSingleSelect === 'true') || (TileElement[0].isSingleSelect === true)) ? false : true}
                                    >
                                        {(
                                            (TileElement[0].isSelectMenuDynamic === 'true') || (TileElement[0].isSelectMenuDynamic === true)) ? (
                                                dropdownData?.[TileElement[0].selectMenuDynamicType].map((e,i) => {
                                                    return <MenuItem key={e.id + "-" + i} value={e.id}><ListItemText primary={e.name} /></MenuItem>;
                                                })
                                            
                                            ) : (
                                                TileElement[0]?.options?.map(
                                                    (e, i) => {
                                                    return <MenuItem key={e.value + i} value={e.value}><ListItemText primary={e.label} /></MenuItem>;
                                                    },
                                                )
                                            )}
                                    </Select>
                                    </FormControl>
                                </div>
                                {/* {!((TileElement[0].isSingleSelect === 'true') || (TileElement[0].isSingleSelect === true)) && (vertualPayload[tabIndex][0].response?.selectResponse) && (vertualPayload[tabIndex][0].response.selectResponse.length > 0) && (
                                        <div className="selected-menu">
                                            {vertualPayload[tabIndex][0].response.selectResponse.map((e,i) => 
                                                <div key={i} className="menu-item-container">
                                                    <div className="title">
                                                        {!((TileElement[0].isSelectMenuDynamic === 'true') || (TileElement[0].isSelectMenuDynamic === true)) ? (
                                                            // {e}
                                                            <>{e}</>

                                                        ) : (
                                                            <>{(getObjectById(dropdownData[TileElement[0].selectMenuDynamicType], e)).name}</>
                                                        )}
                                                    </div>
                                                    <div className="delete">
                                                        <RxCross1 />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) 
                                    } */}
                            </div>
                            )}
                            {(TileElement[0].type === "date") && (
                                <div className="date-field">
                                        
                                    <div className="field-label" >
                                        {TileElement[0].label}
                                    </div>
                                    <div>
                                        <FormControl
                                        sx={{width: {md: '100%', sm: '100%'}}}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    name={TileElement[0].name}
                                                    // value={vertualPayload[tabIndex][0].response.dateResponse}
                                                    // onChange={(event) =>
                                                    //     handleChangeVertualPayload(
                                                    //         JSON.parse(JSON.stringify(vertualPayload)),
                                                    //         event,
                                                    //         "dateField",
                                                    //         tabIndex,
                                                    //         0, //row position
                                                    //     )
                                                    // }
                                                    
                                                    sx={{
                                                        width: '100%',
                                                        borderRadius: '8px',
                                                        "& .MuiOutlinedInput-root": {
                                                            borderRadius: '8px', 
                                                            border: "1px solid #F9F9F9"
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        size={"small"}
                                                        variant="outlined"
                                                        sx={{
                                                            pointerEvents: 'none',
                                                            '& .MuiOutlinedInput-root': {
                                                            'button': {
                                                                pointerEvents: 'all',
                                                            }},
                                                            mt: '6px',
                                                            width: "100%",
                                                            "& fieldset": {
                                                            border: "1px solid #dedede",
                                                            },
                                                            "& .css-k4qjio-MuiFormHelperText-root": {
                                                            backgroundColor: "#ffffff",
                                                            margin: "0px",
                                                            paddingLeft: "0.5rem",
                                                            },
                                                            "& .MuiOutlinedInput-root": {
                                                                border: "1px solid #F9F9F9",
                                                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                borderRadius: '8px',
                                                                'button': {
                                                                    pointerEvents: 'all',
                                                                }
                                                            },
                                                        }}

                                                        {...params}
                                                    />
                                                    )}
                                                />                     
                                            </LocalizationProvider>
                                        </FormControl>
                                    </div>
                                </div>
                            )}
                            {(TileElement[0].type === "empty") && (
                                <div className="empty-field">
                                        
                                    
                                </div>
                            )}
                            

                        </div>
                        <div className="row-half">
                            {(TileElement[1].type === "text") && (
                                <div className="text-field">

                                    <div className="field-label" >
                                        {TileElement[1].label}
                                    </div>
                                    <TextField
                                        name={TileElement[1].name}
                                        // value={vertualPayload[tabIndex][1].response.stringResponse}
                                        // onChange={(event) =>
                                        //     handleChangeVertualPayload(
                                        //         JSON.parse(JSON.stringify(vertualPayload)),
                                        //         event,
                                        //         "textField",
                                        //         tabIndex,
                                        //         1, //row position
                                        //     )
                                        // }
                                        multiline={TileElement[1].isMultiTextFieldRow ?? false}
                                        rows={TileElement[1].textFieldRowSize ?? 1}
                                        id="outlined-multiline-static"
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            width: '100%',
                                            mt: '6px',
                                            "& fieldset": {
                                                borderRadius: '8px',                              
                                                },
                                        }}
                                    />
                                </div>
                            )}
                            {(TileElement[1].type === "number") && (
                                <div className="number-field">

                                    <div className="field-label" >
                                        {TileElement[1].label}
                                    </div>
                                    <TextField
                                        name={TileElement[1].name}
                                        // value={vertualPayload[tabIndex][1].response.stringResponse}
                                        // onChange={(event) =>
                                        //     handleChangeVertualPayload(
                                        //         JSON.parse(JSON.stringify(vertualPayload)),
                                        //         event,
                                        //         "numberField",
                                        //         tabIndex,
                                        //         1, //row position
                                        //     )
                                        // }
                                        multiline={TileElement[1].isMultiTextFieldRow ?? false}
                                        rows={TileElement[1].textFieldRowSize ?? 1}
                                        id="outlined-multiline-static"
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            width: '100%',
                                            mt: '6px',
                                            "& fieldset": {
                                                borderRadius: '8px',                              
                                                },
                                        }}
                                    />
                                </div>
                            )}
                            {(TileElement[1].type === "select") && (
                                <div className="select-field">
                                
                                    <div className="field-label" >
                                        {TileElement[1].label}
                                    </div>
                                    <div>
                                        <FormControl
                                        sx={{width: {md: '100%', sm: '100%'}}}
                                        >
                                        <Select
                                            name={TileElement[1].name}
                                            // value={vertualPayload[tabIndex][1].response.selectResponse || []}
                                            // onChange={(event) =>
                                            //     handleChangeVertualPayload(
                                            //         JSON.parse(JSON.stringify(vertualPayload, )),
                                            //         event,
                                            //         "selectField",
                                            //         tabIndex,
                                            //         1, //row position
                                            //         TileElement
                                            //     )
                                            // }
                                            // renderValue={(selected) =>
                                            //     {
                                            //         if((TileElement[1].isSelectMenuDynamic === 'true') || (TileElement[1].isSelectMenuDynamic === true)) {
                                            //             return getListItemDescription(selected, dropdownData?.[TileElement[1].selectMenuDynamicType], 'name')
                                            //         } else {
                                            //             return  selected + ", "
                                            //         }
                                            //     }
                                            // }

                                            sx={{
                                                width: '100%',
                                                mt: '6px',
                                                "& fieldset": { 
                                                    borderRadius: '8px',   
                                                    
                                                },
                                            }}
                                            size="small"
                                            labelId='demo-simple-select-label'
                                            id='demo-simple-select'
                                            multiple={((TileElement[1].isSingleSelect === 'true') || (TileElement[1].isSingleSelect === true)) ? false : true}
                                        >
                                            {(
                                            (TileElement[1].isSelectMenuDynamic === 'true') || (TileElement[1].isSelectMenuDynamic === true)) ? (
                                                dropdownData?.[TileElement[1].selectMenuDynamicType].map((e,i) => {
                                                    return <MenuItem key={e.id + "-" + i} value={e.id}><ListItemText primary={e.name} /></MenuItem>;
                                                })
                                            
                                            ) : (
                                                TileElement[1]?.options?.map(
                                                    (e, i) => {
                                                    return <MenuItem key={e.value + i} value={e.value}><ListItemText primary={e.label} /></MenuItem>;
                                                    },
                                                )
                                            )}
                                        </Select>
                                        </FormControl>
                                    </div>
                                    {/* {!((TileElement[1].isSingleSelect === 'true') || (TileElement[1].isSingleSelect === true)) && (vertualPayload[tabIndex][1].response?.selectResponse) && (vertualPayload[tabIndex][1].response.selectResponse.length > 1) && (
                                        <div className="selected-menu">
                                            {vertualPayload[tabIndex][1].response.selectResponse.map((e,i) => 
                                                <div className="menu-item-container">
                                                    <div className="title">
                                                        {!((TileElement[1].isSelectMenuDynamic === 'true') || (TileElement[1].isSelectMenuDynamic === true)) ? (
                                                            // {e}
                                                            <>{e}</>

                                                        ) : (
                                                            <>{(getObjectById(dropdownData[TileElement[1].selectMenuDynamicType], e)).name}</>
                                                        )}
                                                    </div>
                                                    <div className="delete">
                                                        <RxCross1 />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ) 
                                    } */}
                                </div>
                            )}
                            {(TileElement[1].type === "date") && (
                                <div className="date-field">
                                        
                                    <div className="field-label" >
                                        {TileElement[1].label}
                                    </div>
                                    <div>
                                        <FormControl
                                        sx={{width: {md: '100%', sm: '100%'}}}
                                        >
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DesktopDatePicker
                                                    name={TileElement[1].name}
                                                    // value={vertualPayload[tabIndex][1].response.dateResponse}
                                                    // onChange={(event) =>
                                                    //     handleChangeVertualPayload(
                                                    //         JSON.parse(JSON.stringify(vertualPayload)),
                                                    //         event,
                                                    //         "dateField",
                                                    //         tabIndex,
                                                    //         1, //row position
                                                    //     )
                                                    // }
                                                    
                                                    sx={{
                                                        width: '100%',
                                                        borderRadius: '8px',
                                                        "& .MuiOutlinedInput-root": {
                                                            borderRadius: '8px', 
                                                            border: "1px solid #F9F9F9"
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                        size={"small"}
                                                        variant="outlined"
                                                        sx={{
                                                            pointerEvents: 'none',
                                                            '& .MuiOutlinedInput-root': {
                                                            'button': {
                                                                pointerEvents: 'all',
                                                            }},
                                                            mt: '6px',
                                                            width: "100%",
                                                            "& fieldset": {
                                                            border: "1px solid #dedede",
                                                            },
                                                            "& .css-k4qjio-MuiFormHelperText-root": {
                                                            backgroundColor: "#ffffff",
                                                            margin: "0px",
                                                            paddingLeft: "0.5rem",
                                                            },
                                                            "& .MuiOutlinedInput-root": {
                                                                border: "1px solid #F9F9F9",
                                                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                borderRadius: '8px',
                                                                'button': {
                                                                    pointerEvents: 'all',
                                                                }
                                                            },
                                                        }}

                                                        {...params}
                                                    />
                                                    )}
                                                />                     
                                            </LocalizationProvider>
                                        </FormControl>
                                    </div>
                                </div>
                            )}
                            {(TileElement[1].type === "empty") && (
                                <div className="empty-field">
                                        
                                    
                                </div>
                            )}

                        </div>
                    </div>


                ) : (
                    

                    <>
                            {(TileElement[0].type === "text") && (
                                <>
                                    <div className="field-row">
                            
                                        <div className="text-field">

                                            <div className="field-label" >
                                                {TileElement[0].label}
                                            </div>
                                            <TextField
                                                name={TileElement[0].name}
                                                // value={vertualPayload[tabIndex][0].response.stringResponse}
                                                // onChange={(event) =>
                                                //     handleChangeVertualPayload(
                                                //         JSON.parse(JSON.stringify(vertualPayload)),
                                                //         event,
                                                //         "textField",
                                                //         tabIndex,
                                                //         0, //row position
                                                //     )
                                                // }
                                                multiline={TileElement[0].isMultiTextFieldRow ?? false}
                                                rows={TileElement[0].textFieldRowSize ?? 1}
                                                id="outlined-multiline-static"
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    width: '100%',
                                                    mt: '6px',
                                                    "& fieldset": {
                                                        borderRadius: '8px',                              
                                                        },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {(TileElement[0].type === "number") && (
                                <>
                                    <div className="field-row">
                            
                                        <div className="number-field">

                                            <div className="field-label" >
                                                {TileElement[0].label}
                                            </div>
                                            <TextField
                                                name={TileElement[0].name}
                                                // value={vertualPayload[tabIndex][0].response.stringResponse}
                                                // onChange={(event) =>
                                                //     handleChangeVertualPayload(
                                                //         JSON.parse(JSON.stringify(vertualPayload)),
                                                //         event,
                                                //         "numberField",
                                                //         tabIndex,
                                                //         0, //row position
                                                //     )
                                                // }
                                                multiline={TileElement[0].isMultiTextFieldRow ?? false}
                                                rows={TileElement[0].textFieldRowSize ?? 1}
                                                id="outlined-multiline-static"
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    width: '100%',
                                                    mt: '6px',
                                                    "& fieldset": {
                                                        borderRadius: '8px',                              
                                                        },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {(TileElement[0].type === "select") && (
                                <>
                                    <div className="field-row">
                                        <div className="select-field">
                                            
                                            <div className="field-label" >
                                                {TileElement[0].label}
                                            </div>
                                            <div>
                                                <FormControl
                                                sx={{width: {md: '100%', sm: '100%'}}}
                                                >
                                                    <Select
                                                        name={TileElement[0].name}
                                                        // value={vertualPayload[tabIndex][0].response.selectResponse || []}
                                                        // renderValue={(selected) =>
                                                        //     {
                                                        //         if((TileElement[0].isSelectMenuDynamic === 'true') || (TileElement[0].isSelectMenuDynamic === true)) {
                                                        //             return getListItemDescription(selected, dropdownData?.[TileElement[0].selectMenuDynamicType], 'name')
                                                        //         } else {
                                                        //             return  selected + ", "
                                                        //         }
                                                        //     }
                                                        // }
                                                        // onChange={(event) =>
                                                        //     handleChangeVertualPayload(
                                                        //         JSON.parse(JSON.stringify(vertualPayload)),
                                                        //         event,
                                                        //         "selectField",
                                                        //         tabIndex,
                                                        //         0, //row position
                                                        //         TileElement
                                                        //     )
                                                        // }
                                                        sx={{
                                                            width: '100%',
                                                            mt: '6px',
                                                            "& fieldset": {
                                                                borderRadius: '8px',   
                                                                
                                                            },
                                                        }}
                                                        multiple={((TileElement[0].isSingleSelect === 'true') || (TileElement[0].isSingleSelect === true)) ? false : true}
                                                        size="small"
                                                        labelId='demo-simple-select-label'
                                                        id='demo-simple-select'
                                                    >
                                                         {(
                                                            (TileElement[0].isSelectMenuDynamic === 'true') || (TileElement[0].isSelectMenuDynamic === true)) ? (
                                                                dropdownData?.[TileElement[0].selectMenuDynamicType].map((e,i) => {
                                                                    return <MenuItem key={e.id + "-" + i} value={e.id}><ListItemText primary={e.name} /></MenuItem>;
                                                                })
                                                            
                                                            ) : (
                                                                TileElement[0]?.options?.map(
                                                                    (e, i) => {
                                                                    return <MenuItem key={e.value + i} value={e.value}><ListItemText primary={e.label} /></MenuItem>;
                                                                    },
                                                                )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {/* {!((TileElement[0].isSingleSelect === 'true') || (TileElement[0].isSingleSelect === true)) && (vertualPayload[tabIndex][0].response?.selectResponse) && (vertualPayload[tabIndex][0].response.selectResponse.length > 0) && (
                                                <div className="selected-menu">
                                                    {vertualPayload[tabIndex][0].response.selectResponse.map((e,i) => 
                                                        <div key={i} className="menu-item-container">
                                                            <div className="title">
                                                                {!((TileElement[0].isSelectMenuDynamic === 'true') || (TileElement[0].isSelectMenuDynamic === true)) ? (
                                                                    // {e}
                                                                    <>{e}</>

                                                                ) : (
                                                                    <>{(getObjectById(dropdownData[TileElement[0].selectMenuDynamicType], e)).name}</>
                                                                )}
                                                            </div>
                                                            <div className="delete">
                                                                <RxCross1 />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) 
                                            } */}
                                        </div>
                                    </div>
                                </>
                            )}
                            {(TileElement[0].type === "date") && (
                                <>
                                    <div className="field-row">
                                        <div className="date-field">
                                            
                                            <div className="field-label" >
                                                {TileElement[0].label}
                                            </div>
                                            <div>
                                                <FormControl
                                                sx={{width: {md: '100%', sm: '100%'}}}
                                                >
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DesktopDatePicker
                                                            // value={vertualPayload[tabIndex][0].response.dateResponse}
                                                            name={TileElement[0].name}
                                                            // onChange={(event) =>
                                                            //     handleChangeVertualPayload(
                                                            //         JSON.parse(JSON.stringify(vertualPayload)),
                                                            //         event,
                                                            //         "dateField",
                                                            //         tabIndex,
                                                            //         0, //row position
                                                            //     )
                                                            // }
                                                            
                                                            sx={{
                                                                width: '100%',
                                                                borderRadius: '8px',
                                                                "& .MuiOutlinedInput-root": {
                                                                    borderRadius: '8px', 
                                                                    border: "1px solid #F9F9F9"
                                                                }
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                size={"small"}
                                                                variant="outlined"
                                                                sx={{
                                                                    pointerEvents: 'none',
                                                                    '& .MuiOutlinedInput-root': {
                                                                    'button': {
                                                                        pointerEvents: 'all',
                                                                    }},
                                                                    mt: '6px',
                                                                    width: "100%",
                                                                    "& fieldset": {
                                                                    border: "1px solid #dedede",
                                                                    },
                                                                    "& .css-k4qjio-MuiFormHelperText-root": {
                                                                    backgroundColor: "#ffffff",
                                                                    margin: "0px",
                                                                    paddingLeft: "0.5rem",
                                                                    },
                                                                    "& .MuiOutlinedInput-root": {
                                                                        border: "1px solid #F9F9F9",
                                                                        borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                        borderRadius: '8px',
                                                                        'button': {
                                                                            pointerEvents: 'all',
                                                                        }
                                                                    },
                                                                }}

                                                                {...params}
                                                            />
                                                            )}
                                                        />                     
                                                    </LocalizationProvider>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                        </>


                )}
            </div>
        

        }
      }

      const createDynamicFormContainer = (TabElement, tabIndex, tempFormDataForContainer) => {
        console.log('createDynamicFormContainer TabElement', TabElement)
        console.log('createDynamicFormContainer tabIndex', tabIndex)
        console.log('createDynamicFormContainer tempFormDataForContainer', tempFormDataForContainer)
        
        if (tempFormDataForContainer[tabIndex] && (tempFormDataForContainer[tabIndex].length > 0)) {
            return (
                <div className="form-container-group">
                    <div className="container-group-header">
                        <div className="group-header-title">
                            Milestone 1
                        </div>
                        <div className="group-header-divider-line">
                            
                        </div>
                        <div className="group-header-delete">

                        <div className="head-delete">
                            <div className="delete-icon">
                                <MdDelete />
                            </div>
                            <div className="title">
                                Delete this goal
                            </div>
                        </div>
                            
                        </div>
                    </div>
                    <div className="container-group-field">
                        <div className="group-field-line-left" />
                        <div className="group-field-margin">
                            <div className="form-group-contant">
                                <div className="group-contant">
                                    {
                                        tempFormDataForContainer[tabIndex].map((groupContainerE, groupContainerI) => {
                                            return groupContainerE[0].container.map((TileElement, tileIndex) => {


                                                return randerDynamicTileElement(TileElement, tileIndex)
                                                        
                                            })
                                        })
                
              
                

            
                                    }
                                </div>
                            </div> 
                        </div>
                    </div>
                    </div>
            )
            // return tempFormDataForContainer[tabIndex].map((groupContainerE, groupContainerI) => {
                
            //     return groupContainerE[0].container.map((TileElement, tileIndex) => {


            //         return randerDynamicTileElement(TileElement, tileIndex)
                    
            //     })
                

            // })
        } else {
            return;
        }


      }

      const createDynamicForm = (data) => {
        if (!data) {
          return;
        }
    
        const tempData = structuredClone(data);
        let dom = [];
    
        if (tempData && tempData?.length > 0) {
            return tempData?.map((TabElement, tabIndex) => (
              <div key={tabIndex}>
                {((TabElement[0].isMultiField === true) || (TabElement[0].isMultiField === 'true')) ? (
                        <div className="field-row">
                            <div className="row-half">
                                {(TabElement[0].type === "text") && (
                                    <div className="text-field">

                                        <div className="field-label" >
                                            {TabElement[0].label}
                                        </div>
                                        <TextField
                                            value={vertualPayload[tabIndex][0].response.stringResponse}
                                            name={TabElement[0].name}
                                            onChange={(event) =>
                                                handleChangeVertualPayload(
                                                    JSON.parse(JSON.stringify(vertualPayload)),
                                                    event,
                                                    "textField",
                                                    tabIndex,
                                                    0, //row position
                                                )
                                            }
                                            multiline={TabElement[0].isMultiTextFieldRow ?? false}
                                            rows={TabElement[0].textFieldRowSize ?? 1}
                                            id="outlined-multiline-static"
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                width: '100%',
                                                mt: '6px',
                                                "& fieldset": {
                                                    borderRadius: '8px',                              
                                                    },
                                            }}
                                        />
                                    </div>
                                )}
                                {(TabElement[0].type === "number") && (
                                    <div className="number-field">

                                        <div className="field-label" >
                                            {TabElement[0].label}
                                        </div>
                                        <TextField
                                            value={vertualPayload[tabIndex][0].response.stringResponse}
                                            name={TabElement[0].name}
                                            onChange={(event) =>
                                                handleChangeVertualPayload(
                                                    JSON.parse(JSON.stringify(vertualPayload)),
                                                    event,
                                                    "numberField",
                                                    tabIndex,
                                                    0, //row position
                                                )
                                            }
                                            multiline={TabElement[0].isMultiTextFieldRow ?? false}
                                            rows={TabElement[0].textFieldRowSize ?? 1}
                                            id="outlined-multiline-static"
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                width: '100%',
                                                mt: '6px',
                                                "& fieldset": {
                                                    borderRadius: '8px',                              
                                                    },
                                            }}
                                        />
                                    </div>
                                )}
                                {(TabElement[0].type === "select") && (
                                    <div className="select-field">
                                    
                                    <div className="field-label" >
                                        {TabElement[0].label}
                                    </div>
                                    <div>
                                        <FormControl
                                        sx={{width: {md: '100%', sm: '100%'}}}
                                        >
                                        <Select
                                            value={vertualPayload[tabIndex][0].response.selectResponse || []}
                                            renderValue={(selected) =>
                                                {
                                                    if((TabElement[1].isSelectMenuDynamic === 'true') || (TabElement[1].isSelectMenuDynamic === true)) {
                                                        return getListItemDescription(selected, dropdownData?.[TabElement[1].selectMenuDynamicType], 'name')
                                                    } else {
                                                        return  selected + ", "
                                                    }
                                                }
                                            }
                                            name={TabElement[0].name}
                                            onChange={(event) =>
                                                handleChangeVertualPayload(
                                                    JSON.parse(JSON.stringify(vertualPayload)),
                                                    event,
                                                    "selectField",
                                                    tabIndex,
                                                    0, //row position
                                                    TabElement
                                                )
                                            }
                                            sx={{
                                                width: '100%',
                                                mt: '6px',
                                                "& fieldset": { 
                                                    borderRadius: '8px',   
                                                    
                                                },
                                            }}
                                            size="small"
                                            labelId='demo-simple-select-label'
                                            id='demo-simple-select'
                                            multiple={((TabElement[0].isSingleSelect === 'true') || (TabElement[0].isSingleSelect === true)) ? false : true}
                                        >
                                            {(
                                                (TabElement[0].isSelectMenuDynamic === 'true') || (TabElement[0].isSelectMenuDynamic === true)) ? (
                                                    dropdownData?.[TabElement[0].selectMenuDynamicType].map((e,i) => {
                                                        return <MenuItem key={e.id + "-" + i} value={e.id}><ListItemText primary={e.name} /></MenuItem>;
                                                    })
                                                
                                                ) : (
                                                    TabElement[0]?.options?.map(
                                                        (e, i) => {
                                                        return <MenuItem key={e.value + i} value={e.value}><ListItemText primary={e.label} /></MenuItem>;
                                                        },
                                                    )
                                                )}
                                        </Select>
                                        </FormControl>
                                    </div>
                                    {!((TabElement[0].isSingleSelect === 'true') || (TabElement[0].isSingleSelect === true)) && (vertualPayload[tabIndex][0].response?.selectResponse) && (vertualPayload[tabIndex][0].response.selectResponse.length > 0) && (
                                            <div className="selected-menu">
                                                {vertualPayload[tabIndex][0].response.selectResponse.map((e,i) => 
                                                    <div key={i} className="menu-item-container">
                                                        <div className="title">
                                                            {!((TabElement[0].isSelectMenuDynamic === 'true') || (TabElement[0].isSelectMenuDynamic === true)) ? (
                                                                // {e}
                                                                <>{e}</>

                                                            ) : (
                                                                <>{(getObjectById(dropdownData[TabElement[0].selectMenuDynamicType], e)).name}</>
                                                            )}
                                                        </div>
                                                        <div className="delete">
                                                            <RxCross1 />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) 
                                        }
                                </div>
                                )}
                                {(TabElement[0].type === "date") && (
                                    <div className="date-field">
                                            
                                        <div className="field-label" >
                                            {TabElement[0].label}
                                        </div>
                                        <div>
                                            <FormControl
                                            sx={{width: {md: '100%', sm: '100%'}}}
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        name={TabElement[0].name}
                                                        value={vertualPayload[tabIndex][0].response.dateResponse}
                                                        onChange={(event) =>
                                                            handleChangeVertualPayload(
                                                                JSON.parse(JSON.stringify(vertualPayload)),
                                                                event,
                                                                "dateField",
                                                                tabIndex,
                                                                0, //row position
                                                            )
                                                        }
                                                        
                                                        sx={{
                                                            width: '100%',
                                                            borderRadius: '8px',
                                                            "& .MuiOutlinedInput-root": {
                                                                borderRadius: '8px', 
                                                                border: "1px solid #F9F9F9"
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            size={"small"}
                                                            variant="outlined"
                                                            sx={{
                                                                pointerEvents: 'none',
                                                                '& .MuiOutlinedInput-root': {
                                                                'button': {
                                                                    pointerEvents: 'all',
                                                                }},
                                                                mt: '6px',
                                                                width: "100%",
                                                                "& fieldset": {
                                                                border: "1px solid #dedede",
                                                                },
                                                                "& .css-k4qjio-MuiFormHelperText-root": {
                                                                backgroundColor: "#ffffff",
                                                                margin: "0px",
                                                                paddingLeft: "0.5rem",
                                                                },
                                                                "& .MuiOutlinedInput-root": {
                                                                    border: "1px solid #F9F9F9",
                                                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                    borderRadius: '8px',
                                                                    'button': {
                                                                        pointerEvents: 'all',
                                                                    }
                                                                },
                                                            }}

                                                            {...params}
                                                        />
                                                        )}
                                                    />                     
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>
                                    </div>
                                )}
                                {(TabElement[0].type === "empty") && (
                                    <div className="empty-field">
                                            
                                        
                                    </div>
                                )}
                                

                            </div>
                            <div className="row-half">
                                {(TabElement[1].type === "text") && (
                                    <div className="text-field">

                                        <div className="field-label" >
                                            {TabElement[1].label}
                                        </div>
                                        <TextField
                                            name={TabElement[1].name}
                                            value={vertualPayload[tabIndex][1].response.stringResponse}
                                            onChange={(event) =>
                                                handleChangeVertualPayload(
                                                    JSON.parse(JSON.stringify(vertualPayload)),
                                                    event,
                                                    "textField",
                                                    tabIndex,
                                                    1, //row position
                                                )
                                            }
                                            multiline={TabElement[1].isMultiTextFieldRow ?? false}
                                            rows={TabElement[1].textFieldRowSize ?? 1}
                                            id="outlined-multiline-static"
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                width: '100%',
                                                mt: '6px',
                                                "& fieldset": {
                                                    borderRadius: '8px',                              
                                                    },
                                            }}
                                        />
                                    </div>
                                )}
                                {(TabElement[1].type === "number") && (
                                    <div className="number-field">

                                        <div className="field-label" >
                                            {TabElement[1].label}
                                        </div>
                                        <TextField
                                            name={TabElement[1].name}
                                            value={vertualPayload[tabIndex][1].response.stringResponse}
                                            onChange={(event) =>
                                                handleChangeVertualPayload(
                                                    JSON.parse(JSON.stringify(vertualPayload)),
                                                    event,
                                                    "numberField",
                                                    tabIndex,
                                                    1, //row position
                                                )
                                            }
                                            multiline={TabElement[1].isMultiTextFieldRow ?? false}
                                            rows={TabElement[1].textFieldRowSize ?? 1}
                                            id="outlined-multiline-static"
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                width: '100%',
                                                mt: '6px',
                                                "& fieldset": {
                                                    borderRadius: '8px',                              
                                                    },
                                            }}
                                        />
                                    </div>
                                )}
                                {(TabElement[1].type === "select") && (
                                    <div className="select-field">
                                    
                                        <div className="field-label" >
                                            {TabElement[1].label}
                                        </div>
                                        <div>
                                            <FormControl
                                            sx={{width: {md: '100%', sm: '100%'}}}
                                            >
                                            <Select
                                                name={TabElement[1].name}
                                                value={vertualPayload[tabIndex][1].response.selectResponse || []}
                                                onChange={(event) =>
                                                    handleChangeVertualPayload(
                                                        JSON.parse(JSON.stringify(vertualPayload, )),
                                                        event,
                                                        "selectField",
                                                        tabIndex,
                                                        1, //row position
                                                        TabElement
                                                    )
                                                }
                                                renderValue={(selected) =>
                                                    {
                                                        if((TabElement[1].isSelectMenuDynamic === 'true') || (TabElement[1].isSelectMenuDynamic === true)) {
                                                            return getListItemDescription(selected, dropdownData?.[TabElement[1].selectMenuDynamicType], 'name')
                                                        } else {
                                                            return  selected + ", "
                                                        }
                                                    }
                                                }

                                                sx={{
                                                    width: '100%',
                                                    mt: '6px',
                                                    "& fieldset": { 
                                                        borderRadius: '8px',   
                                                        
                                                    },
                                                }}
                                                size="small"
                                                labelId='demo-simple-select-label'
                                                id='demo-simple-select'
                                                multiple={((TabElement[1].isSingleSelect === 'true') || (TabElement[1].isSingleSelect === true)) ? false : true}
                                            >
                                                {(
                                                (TabElement[1].isSelectMenuDynamic === 'true') || (TabElement[1].isSelectMenuDynamic === true)) ? (
                                                    dropdownData?.[TabElement[1].selectMenuDynamicType].map((e,i) => {
                                                        return <MenuItem key={e.id + "-" + i} value={e.id}><ListItemText primary={e.name} /></MenuItem>;
                                                    })
                                                
                                                ) : (
                                                    TabElement[1]?.options?.map(
                                                        (e, i) => {
                                                        return <MenuItem key={e.value + i} value={e.value}><ListItemText primary={e.label} /></MenuItem>;
                                                        },
                                                    )
                                                )}
                                            </Select>
                                            </FormControl>
                                        </div>
                                        {!((TabElement[1].isSingleSelect === 'true') || (TabElement[1].isSingleSelect === true)) && (vertualPayload[tabIndex][1].response?.selectResponse) && (vertualPayload[tabIndex][1].response.selectResponse.length > 1) && (
                                            <div className="selected-menu">
                                                {vertualPayload[tabIndex][1].response.selectResponse.map((e,i) => 
                                                    <div className="menu-item-container">
                                                        <div className="title">
                                                            {!((TabElement[1].isSelectMenuDynamic === 'true') || (TabElement[1].isSelectMenuDynamic === true)) ? (
                                                                // {e}
                                                                <>{e}</>

                                                            ) : (
                                                                <>{(getObjectById(dropdownData[TabElement[1].selectMenuDynamicType], e)).name}</>
                                                            )}
                                                        </div>
                                                        <div className="delete">
                                                            <RxCross1 />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) 
                                        }
                                    </div>
                                )}
                                {(TabElement[1].type === "date") && (
                                    <div className="date-field">
                                            
                                        <div className="field-label" >
                                            {TabElement[1].label}
                                        </div>
                                        <div>
                                            <FormControl
                                            sx={{width: {md: '100%', sm: '100%'}}}
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        name={TabElement[1].name}
                                                        value={vertualPayload[tabIndex][1].response.dateResponse}
                                                        onChange={(event) =>
                                                            handleChangeVertualPayload(
                                                                JSON.parse(JSON.stringify(vertualPayload)),
                                                                event,
                                                                "dateField",
                                                                tabIndex,
                                                                1, //row position
                                                            )
                                                        }
                                                        
                                                        sx={{
                                                            width: '100%',
                                                            borderRadius: '8px',
                                                            "& .MuiOutlinedInput-root": {
                                                                borderRadius: '8px', 
                                                                border: "1px solid #F9F9F9"
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                            size={"small"}
                                                            variant="outlined"
                                                            sx={{
                                                                pointerEvents: 'none',
                                                                '& .MuiOutlinedInput-root': {
                                                                'button': {
                                                                    pointerEvents: 'all',
                                                                }},
                                                                mt: '6px',
                                                                width: "100%",
                                                                "& fieldset": {
                                                                border: "1px solid #dedede",
                                                                },
                                                                "& .css-k4qjio-MuiFormHelperText-root": {
                                                                backgroundColor: "#ffffff",
                                                                margin: "0px",
                                                                paddingLeft: "0.5rem",
                                                                },
                                                                "& .MuiOutlinedInput-root": {
                                                                    border: "1px solid #F9F9F9",
                                                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                    borderRadius: '8px',
                                                                    'button': {
                                                                        pointerEvents: 'all',
                                                                    }
                                                                },
                                                            }}

                                                            {...params}
                                                        />
                                                        )}
                                                    />                     
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>
                                    </div>
                                )}
                                {(TabElement[1].type === "empty") && (
                                    <div className="empty-field">
                                            
                                        
                                    </div>
                                )}

                            </div>
                        </div>
                ) : (
                    (TabElement[0].container.length > 0) ? (
                        <>
                        {createDynamicFormContainer(TabElement, tabIndex, structuredClone(formDataForContainer))}
                        </>
                    ) : (
                        <>
                            {(TabElement[0].type === "text") && (
                                <>
                                    <div className="field-row">
                            
                                        <div className="text-field">

                                            <div className="field-label" >
                                                {TabElement[0].label}
                                            </div>
                                            <TextField
                                                name={TabElement[0].name}
                                                value={vertualPayload[tabIndex][0].response.stringResponse}
                                                onChange={(event) =>
                                                    handleChangeVertualPayload(
                                                        JSON.parse(JSON.stringify(vertualPayload)),
                                                        event,
                                                        "textField",
                                                        tabIndex,
                                                        0, //row position
                                                    )
                                                }
                                                multiline={TabElement[0].isMultiTextFieldRow ?? false}
                                                rows={TabElement[0].textFieldRowSize ?? 1}
                                                id="outlined-multiline-static"
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    width: '100%',
                                                    mt: '6px',
                                                    "& fieldset": {
                                                        borderRadius: '8px',                              
                                                        },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {(TabElement[0].type === "number") && (
                                <>
                                    <div className="field-row">
                            
                                        <div className="number-field">

                                            <div className="field-label" >
                                                {TabElement[0].label}
                                            </div>
                                            <TextField
                                                name={TabElement[0].name}
                                                value={vertualPayload[tabIndex][0].response.stringResponse}
                                                onChange={(event) =>
                                                    handleChangeVertualPayload(
                                                        JSON.parse(JSON.stringify(vertualPayload)),
                                                        event,
                                                        "numberField",
                                                        tabIndex,
                                                        0, //row position
                                                    )
                                                }
                                                multiline={TabElement[0].isMultiTextFieldRow ?? false}
                                                rows={TabElement[0].textFieldRowSize ?? 1}
                                                id="outlined-multiline-static"
                                                variant="outlined"
                                                size="small"
                                                sx={{
                                                    width: '100%',
                                                    mt: '6px',
                                                    "& fieldset": {
                                                        borderRadius: '8px',                              
                                                        },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            )}
                            {(TabElement[0].type === "select") && (
                                <>
                                    <div className="field-row">
                                        <div className="select-field">
                                            
                                            <div className="field-label" >
                                                {TabElement[0].label}
                                            </div>
                                            <div>
                                                <FormControl
                                                sx={{width: {md: '100%', sm: '100%'}}}
                                                >
                                                    <Select
                                                        name={TabElement[0].name}
                                                        value={vertualPayload[tabIndex][0].response.selectResponse || []}
                                                        renderValue={(selected) =>
                                                            {
                                                                if((TabElement[0].isSelectMenuDynamic === 'true') || (TabElement[0].isSelectMenuDynamic === true)) {
                                                                    return getListItemDescription(selected, dropdownData?.[TabElement[0].selectMenuDynamicType], 'name')
                                                                } else {
                                                                    return  selected + ", "
                                                                }
                                                            }
                                                        }
                                                        onChange={(event) =>
                                                            handleChangeVertualPayload(
                                                                JSON.parse(JSON.stringify(vertualPayload)),
                                                                event,
                                                                "selectField",
                                                                tabIndex,
                                                                0, //row position
                                                                TabElement
                                                            )
                                                        }
                                                        sx={{
                                                            width: '100%',
                                                            mt: '6px',
                                                            "& fieldset": {
                                                                borderRadius: '8px',   
                                                                
                                                            },
                                                        }}
                                                        multiple={((TabElement[0].isSingleSelect === 'true') || (TabElement[0].isSingleSelect === true)) ? false : true}
                                                        size="small"
                                                        labelId='demo-simple-select-label'
                                                        id='demo-simple-select'
                                                    >
                                                         {(
                                                            (TabElement[0].isSelectMenuDynamic === 'true') || (TabElement[0].isSelectMenuDynamic === true)) ? (
                                                                dropdownData?.[TabElement[0].selectMenuDynamicType].map((e,i) => {
                                                                    return <MenuItem key={e.id + "-" + i} value={e.id}><ListItemText primary={e.name} /></MenuItem>;
                                                                })
                                                            
                                                            ) : (
                                                                TabElement[0]?.options?.map(
                                                                    (e, i) => {
                                                                    return <MenuItem key={e.value + i} value={e.value}><ListItemText primary={e.label} /></MenuItem>;
                                                                    },
                                                                )
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            {!((TabElement[0].isSingleSelect === 'true') || (TabElement[0].isSingleSelect === true)) && (vertualPayload[tabIndex][0].response?.selectResponse) && (vertualPayload[tabIndex][0].response.selectResponse.length > 0) && (
                                                <div className="selected-menu">
                                                    {vertualPayload[tabIndex][0].response.selectResponse.map((e,i) => 
                                                        <div key={i} className="menu-item-container">
                                                            <div className="title">
                                                                {!((TabElement[0].isSelectMenuDynamic === 'true') || (TabElement[0].isSelectMenuDynamic === true)) ? (
                                                                    // {e}
                                                                    <>{e}</>

                                                                ) : (
                                                                    <>{(getObjectById(dropdownData[TabElement[0].selectMenuDynamicType], e)).name}</>
                                                                )}
                                                            </div>
                                                            <div className="delete">
                                                                <RxCross1 />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) 
                                            }
                                        </div>
                                    </div>
                                </>
                            )}
                            {(TabElement[0].type === "date") && (
                                <>
                                    <div className="field-row">
                                        <div className="date-field">
                                            
                                            <div className="field-label" >
                                                {TabElement[0].label}
                                            </div>
                                            <div>
                                                <FormControl
                                                sx={{width: {md: '100%', sm: '100%'}}}
                                                >
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DesktopDatePicker
                                                            value={vertualPayload[tabIndex][0].response.dateResponse}
                                                            name={TabElement[0].name}
                                                            onChange={(event) =>
                                                                handleChangeVertualPayload(
                                                                    JSON.parse(JSON.stringify(vertualPayload)),
                                                                    event,
                                                                    "dateField",
                                                                    tabIndex,
                                                                    0, //row position
                                                                )
                                                            }
                                                            
                                                            sx={{
                                                                width: '100%',
                                                                borderRadius: '8px',
                                                                "& .MuiOutlinedInput-root": {
                                                                    borderRadius: '8px', 
                                                                    border: "1px solid #F9F9F9"
                                                                }
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                size={"small"}
                                                                variant="outlined"
                                                                sx={{
                                                                    pointerEvents: 'none',
                                                                    '& .MuiOutlinedInput-root': {
                                                                    'button': {
                                                                        pointerEvents: 'all',
                                                                    }},
                                                                    mt: '6px',
                                                                    width: "100%",
                                                                    "& fieldset": {
                                                                    border: "1px solid #dedede",
                                                                    },
                                                                    "& .css-k4qjio-MuiFormHelperText-root": {
                                                                    backgroundColor: "#ffffff",
                                                                    margin: "0px",
                                                                    paddingLeft: "0.5rem",
                                                                    },
                                                                    "& .MuiOutlinedInput-root": {
                                                                        border: "1px solid #F9F9F9",
                                                                        borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                        borderRadius: '8px',
                                                                        'button': {
                                                                            pointerEvents: 'all',
                                                                        }
                                                                    },
                                                                }}

                                                                {...params}
                                                            />
                                                            )}
                                                        />                     
                                                    </LocalizationProvider>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                        </>
                    )
                )}
              </div>
            ));
          }
    };


    return (
        <div className="emp-goals-form-page">

            <div className="goals-form-page-container">
                <div className="form-page-container-margin">


                    <div className="form-goal-head">
                        <div className="goal-head">
                            <div className="head-title">
                                Editing a goal
                            </div>
                            <div className="head-delete">
                                <div className="delete-icon">
                                    <MdDelete />
                                </div>
                                <div className="title">
                                    Delete this goal
                                </div>
                            </div>

                        </div>
                    </div>
             

              
                    <div className="form-goal-contant">
                        <div className="goal-contant">

                            <>

                            {(formData && (formData.responseFormField.length > 0)) &&
                                createDynamicForm(
                                    formData.responseFormField
                                )
                            }
                            
                            </>

                            <div className="field-row">
                                <div className="text-field">

                                    <div className="field-label" >
                                        Goal Title
                                    </div>
                                    <TextField
                                        name="responseString"
                                        // value={
                                        //     vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.responseString
                                        // }
                                        // onChange={(event) =>
                                        //     handleChangeVertualPayload(
                                        //     event,
                                        //     "textField",
                                        //     tabIndex,
                                        //     tileIndex,
                                        //     nestedSubsectionI
                                        //     )
                                        // }
                                        id="outlined-multiline-static"
                                        // label="njknj"
                                        variant="outlined"
                                        // placeholder={`Provide your feedback here ${
                                        //     nestedSubsectionE.configObj
                                        //     .minCharacterLimit ||
                                        //     nestedSubsectionE.configObj
                                        //     .maxCharacterLimit
                                        //     ? `(limit: ${
                                        //         nestedSubsectionE
                                        //             .configObj
                                        //             .minCharacterLimit
                                        //             ? ` min: ${nestedSubsectionE.configObj.minCharacterLimit}`
                                        //             : ""
                                        //         }  ${
                                        //         nestedSubsectionE
                                        //             .configObj
                                        //             .maxCharacterLimit
                                        //             ? `max: ${nestedSubsectionE.configObj.maxCharacterLimit} `
                                        //             : ""
                                        //         }char)`
                                        //     : ""
                                        // }`}
                                        // multiline
                                        size="small"
                                        sx={{
                                            width: '100%',
                                            // borderRadius: '8px',

                                            // mt: 2,
                                            mt: '6px',
                                            // backgroundColor:"#F9F9F9",
                                            // borderRadius:"1rem",
                                            "& fieldset": {
                                                // border: "1px solid #F9F9F9",
                                                // borderRadius:"1rem",  
                                                borderRadius: '8px',   
                                                                            
                                                },
                                        }}
                                        // rows={1}
                                        // sx={textAreaStyle}
                                        // error={
                                        //     vertualError[tabIndex]
                                        //     .subSections[tileIndex]
                                        //     .subSections[
                                        //     nestedSubsectionI
                                        //     ].assessmentResponseObjectDTO
                                        //     .responseString.isError
                                        // }
                                        // helperText={
                                        //     vertualError[tabIndex]
                                        //     .subSections[tileIndex]
                                        //     .subSections[
                                        //     nestedSubsectionI
                                        //     ].assessmentResponseObjectDTO
                                        //     .responseString.isError &&
                                        //     vertualError[tabIndex]
                                        //     .subSections[tileIndex]
                                        //     .subSections[
                                        //     nestedSubsectionI
                                        //     ].assessmentResponseObjectDTO
                                        //     .responseString.errorMessage
                                        // }
                                    />
                                </div>
                            </div>

                            <div className="field-row">
                                <div className="text-field">

                                    <div className="field-label" >
                                        Goal Description (0/1000 Characters)
                                    </div>
                                    <TextField
                                        name="responseString"
                                        // value={
                                        //     vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.responseString
                                        // }
                                        // onChange={(event) =>
                                        //     handleChangeVertualPayload(
                                        //     event,
                                        //     "textField",
                                        //     tabIndex,
                                        //     tileIndex,
                                        //     nestedSubsectionI
                                        //     )
                                        // }
                                        id="outlined-multiline-static"
                                        // label="njknj"
                                        variant="outlined"
                                        // placeholder={`Provide your feedback here ${
                                        //     nestedSubsectionE.configObj
                                        //     .minCharacterLimit ||
                                        //     nestedSubsectionE.configObj
                                        //     .maxCharacterLimit
                                        //     ? `(limit: ${
                                        //         nestedSubsectionE
                                        //             .configObj
                                        //             .minCharacterLimit
                                        //             ? ` min: ${nestedSubsectionE.configObj.minCharacterLimit}`
                                        //             : ""
                                        //         }  ${
                                        //         nestedSubsectionE
                                        //             .configObj
                                        //             .maxCharacterLimit
                                        //             ? `max: ${nestedSubsectionE.configObj.maxCharacterLimit} `
                                        //             : ""
                                        //         }char)`
                                        //     : ""
                                        // }`}
                                        size="small"
                                        sx={{
                                            width: '100%',
                                            // borderRadius: '8px',
                                            
                                            // mt: 2,
                                            mt: '6px',
                                            // backgroundColor:"#F9F9F9",
                                            // borderRadius:"1rem",
                                            "& fieldset": {
                                                // border: "1px solid #F9F9F9",
                                                // borderRadius:"1rem",  
                                                borderRadius: '8px',   
                                                
                                            },
                                        }}
                                        multiline
                                        rows={0}
                                    />
                                </div>
                            </div>

                            <div className="field-row">
                                <div className="row-half">
                                    <div className="select-field">
                                        
                                        <div className="field-label" >
                                            Add Stakeholders 
                                        </div>
                                        <div>
                                            <FormControl
                                            sx={{width: {md: '100%', sm: '100%'}}}
                                            >
                                            {/* <InputLabel id='demo-simple-select-label'>
                                                Trending Rating
                                            </InputLabel> */}
                                            <Select

                                                sx={{
                                                    width: '100%',
                                                    // borderRadius: '8px',
                                                    
                                                    // mt: 2,
                                                    mt: '6px',
                                                    // backgroundColor:"#F9F9F9",
                                                    // borderRadius:"1rem",
                                                    "& fieldset": {
                                                        // border: "1px solid #F9F9F9",
                                                        // borderRadius:"1rem",  
                                                        borderRadius: '8px',   
                                                        
                                                    },
                                                }}
                                                size="small"
                                                name='rating'
                                                labelId='demo-simple-select-label'
                                                id='demo-simple-select'
                                                // value={
                                                // vertualFormPayload[tabIndex]
                                                // .subSections[tileIndex]
                                                // .subSections[
                                                // nestedSubsectionI
                                                // ].assessmentResponseObjectDTO
                                                //     .rating
                                                // }
                                                // renderValue={(selected) =>
                                                // selected.name
                                                // }
                                                // onChange={(event) =>
                                                // handleChangeVertualPayload(
                                                //     event,
                                                //     'dropDownSelect',
                                                //     tabIndex,
                                                //     tileIndex,
                                                //     nestedSubsectionI,
                                                // )
                                                // }
                                                // label='Trending Rating'
                                            >
                                                {/* {nestedSubsectionE.configObj?.selectableRatings?.tableData.sort((a, b) => a?.scale - b?.scale)?.map(
                                                (e) => {
                                                    return <MenuItem style={{background: (vertualFormPayload[tabIndex]
                                                    .subSections[tileIndex]
                                                    .subSections[
                                                    nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                    .rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                                                },
                                                )} */}
                                            </Select>
                                            {/* {vertualError[tabIndex]
                                                .subSections[tileIndex]
                                                .subSections[
                                                nestedSubsectionI
                                            ].assessmentResponseObjectDTO
                                                .rating.isError && (
                                                <FormHelperText className='Mui-error'>
                                                {
                                                    vertualError[tabIndex]
                                                    .subSections[tileIndex]
                                                    .subSections[
                                                    nestedSubsectionI
                                                ].assessmentResponseObjectDTO
                                                    .rating.errorMessage
                                                }
                                                </FormHelperText>
                                            )} */}
                                            </FormControl>
                                        </div>
                                        <div className="selected-menu">
                                            <div className="menu-item-container">
                                                <div className="title">
                                                    Micheal William

                                                </div>
                                                <div className="delete">
                                                    <RxCross1 />
                                                </div>
                                            </div>
                                            <div className="menu-item-container">
                                                <div className="title">
                                                    Wallace Mason

                                                </div>
                                                <div className="delete">
                                                    <RxCross1 />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row-half">
                                    <div className="select-field">
                                        
                                        <div className="field-label" >
                                            Goal Cycle
                                        </div>
                                        <div>
                                            <FormControl
                                            sx={{width: {md: '100%', sm: '100%'}}}
                                            >
                                            {/* <InputLabel id='demo-simple-select-label'>
                                                Trending Rating
                                            </InputLabel> */}
                                            <Select

                                                sx={{
                                                    width: '100%',
                                                    // borderRadius: '8px',
                                                    
                                                    // mt: 2,
                                                    mt: '6px',
                                                    // backgroundColor:"#F9F9F9",
                                                    // borderRadius:"1rem",
                                                    "& fieldset": {
                                                        // border: "1px solid #F9F9F9",
                                                        // borderRadius:"1rem",  
                                                        borderRadius: '8px',   
                                                        
                                                    },
                                                }}
                                                size="small"
                                                name='rating'
                                                labelId='demo-simple-select-label'
                                                id='demo-simple-select'
                                                // value={
                                                // vertualFormPayload[tabIndex]
                                                // .subSections[tileIndex]
                                                // .subSections[
                                                // nestedSubsectionI
                                                // ].assessmentResponseObjectDTO
                                                //     .rating
                                                // }
                                                // renderValue={(selected) =>
                                                // selected.name
                                                // }
                                                // onChange={(event) =>
                                                // handleChangeVertualPayload(
                                                //     event,
                                                //     'dropDownSelect',
                                                //     tabIndex,
                                                //     tileIndex,
                                                //     nestedSubsectionI,
                                                // )
                                                // }
                                                // label='Trending Rating'
                                            >
                                                {/* {nestedSubsectionE.configObj?.selectableRatings?.tableData.sort((a, b) => a?.scale - b?.scale)?.map(
                                                (e) => {
                                                    return <MenuItem style={{background: (vertualFormPayload[tabIndex]
                                                    .subSections[tileIndex]
                                                    .subSections[
                                                    nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                    .rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                                                },
                                                )} */}
                                            </Select>
                                            {/* {vertualError[tabIndex]
                                                .subSections[tileIndex]
                                                .subSections[
                                                nestedSubsectionI
                                            ].assessmentResponseObjectDTO
                                                .rating.isError && (
                                                <FormHelperText className='Mui-error'>
                                                {
                                                    vertualError[tabIndex]
                                                    .subSections[tileIndex]
                                                    .subSections[
                                                    nestedSubsectionI
                                                ].assessmentResponseObjectDTO
                                                    .rating.errorMessage
                                                }
                                                </FormHelperText>
                                            )} */}
                                            </FormControl>
                                        </div>
                                        <div className="selected-menu">
                                            <div className="menu-item-container">
                                                <div className="title">
                                                    Q1 cycle 2024

                                                </div>
                                                <div className="delete">
                                                    <RxCross1 />
                                                </div>
                                            </div>
                                            <div className="menu-item-container">
                                                <div className="title">
                                                    Q2 cycle 2024

                                                </div>
                                                <div className="delete">
                                                    <RxCross1 />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="field-row">
                                <div className="row-half">
                                    <div className="select-field">
                                        
                                        <div className="field-label" >
                                            Tag Goal
                                        </div>
                                        <div>
                                            <FormControl
                                            sx={{width: {md: '100%', sm: '100%'}}}
                                            >
                                            {/* <InputLabel id='demo-simple-select-label'>
                                                Trending Rating
                                            </InputLabel> */}
                                            <Select

                                                sx={{
                                                    width: '100%',
                                                    // borderRadius: '8px',
                                                    
                                                    // mt: 2,
                                                    mt: '6px',
                                                    // backgroundColor:"#F9F9F9",
                                                    // borderRadius:"1rem",
                                                    "& fieldset": {
                                                        // border: "1px solid #F9F9F9",
                                                        // borderRadius:"1rem",  
                                                        borderRadius: '8px',   
                                                        
                                                    },
                                                }}
                                                size="small"
                                                name='rating'
                                                labelId='demo-simple-select-label'
                                                id='demo-simple-select'
                                                // value={
                                                // vertualFormPayload[tabIndex]
                                                // .subSections[tileIndex]
                                                // .subSections[
                                                // nestedSubsectionI
                                                // ].assessmentResponseObjectDTO
                                                //     .rating
                                                // }
                                                // renderValue={(selected) =>
                                                // selected.name
                                                // }
                                                // onChange={(event) =>
                                                // handleChangeVertualPayload(
                                                //     event,
                                                //     'dropDownSelect',
                                                //     tabIndex,
                                                //     tileIndex,
                                                //     nestedSubsectionI,
                                                // )
                                                // }
                                                // label='Trending Rating'
                                            >
                                                {/* {nestedSubsectionE.configObj?.selectableRatings?.tableData.sort((a, b) => a?.scale - b?.scale)?.map(
                                                (e) => {
                                                    return <MenuItem style={{background: (vertualFormPayload[tabIndex]
                                                    .subSections[tileIndex]
                                                    .subSections[
                                                    nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                    .rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                                                },
                                                )} */}
                                            </Select>
                                            {/* {vertualError[tabIndex]
                                                .subSections[tileIndex]
                                                .subSections[
                                                nestedSubsectionI
                                            ].assessmentResponseObjectDTO
                                                .rating.isError && (
                                                <FormHelperText className='Mui-error'>
                                                {
                                                    vertualError[tabIndex]
                                                    .subSections[tileIndex]
                                                    .subSections[
                                                    nestedSubsectionI
                                                ].assessmentResponseObjectDTO
                                                    .rating.errorMessage
                                                }
                                                </FormHelperText>
                                            )} */}
                                            </FormControl>
                                        </div>
                                        {/* <div className="selected-menu">
                                            <div className="menu-item-container">
                                                <div className="title">
                                                    Micheal William

                                                </div>
                                                <div className="delete">
                                                    <RxCross1 />
                                                </div>
                                            </div>
                                            <div className="menu-item-container">
                                                <div className="title">
                                                    Wallace Mason

                                                </div>
                                                <div className="delete">
                                                    <RxCross1 />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>

                                </div>
                                <div className="row-half">
                                    <div className="select-field">
                                        
                                        <div className="field-label" >
                                            Goal Weightage in %
                                        </div>
                                        <div>
                                            <FormControl
                                            sx={{width: {md: '100%', sm: '100%'}}}
                                            >
                                            {/* <InputLabel id='demo-simple-select-label'>
                                                Trending Rating
                                            </InputLabel> */}
                                            <Select

                                                sx={{
                                                    width: '100%',
                                                    // borderRadius: '8px',
                                                    
                                                    // mt: 2,
                                                    mt: '6px',
                                                    // backgroundColor:"#F9F9F9",
                                                    // borderRadius:"1rem",
                                                    "& fieldset": {
                                                        // border: "1px solid #F9F9F9",
                                                        // borderRadius:"1rem",  
                                                        borderRadius: '8px',   
                                                        
                                                    },
                                                }}
                                                size="small"
                                                name='rating'
                                                labelId='demo-simple-select-label'
                                                id='demo-simple-select'
                                                // value={
                                                // vertualFormPayload[tabIndex]
                                                // .subSections[tileIndex]
                                                // .subSections[
                                                // nestedSubsectionI
                                                // ].assessmentResponseObjectDTO
                                                //     .rating
                                                // }
                                                // renderValue={(selected) =>
                                                // selected.name
                                                // }
                                                // onChange={(event) =>
                                                // handleChangeVertualPayload(
                                                //     event,
                                                //     'dropDownSelect',
                                                //     tabIndex,
                                                //     tileIndex,
                                                //     nestedSubsectionI,
                                                // )
                                                // }
                                                // label='Trending Rating'
                                            >
                                                {/* {nestedSubsectionE.configObj?.selectableRatings?.tableData.sort((a, b) => a?.scale - b?.scale)?.map(
                                                (e) => {
                                                    return <MenuItem style={{background: (vertualFormPayload[tabIndex]
                                                    .subSections[tileIndex]
                                                    .subSections[
                                                    nestedSubsectionI
                                                    ].assessmentResponseObjectDTO
                                                    .rating?.id == e?.id) ? '#f6f6f6' : ''}} key={e.id} value={e}><ListItemText primary={e.name} /></MenuItem>;
                                                },
                                                )} */}
                                            </Select>
                                            {/* {vertualError[tabIndex]
                                                .subSections[tileIndex]
                                                .subSections[
                                                nestedSubsectionI
                                            ].assessmentResponseObjectDTO
                                                .rating.isError && (
                                                <FormHelperText className='Mui-error'>
                                                {
                                                    vertualError[tabIndex]
                                                    .subSections[tileIndex]
                                                    .subSections[
                                                    nestedSubsectionI
                                                ].assessmentResponseObjectDTO
                                                    .rating.errorMessage
                                                }
                                                </FormHelperText>
                                            )} */}
                                            </FormControl>
                                        </div>
                                        {/* <div className="selected-menu">
                                            <div className="menu-item-container">
                                                <div className="title">
                                                    Q1 cycle 2024

                                                </div>
                                                <div className="delete">
                                                    <RxCross1 />
                                                </div>
                                            </div>
                                            <div className="menu-item-container">
                                                <div className="title">
                                                    Q2 cycle 2024

                                                </div>
                                                <div className="delete">
                                                    <RxCross1 />
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>

                                </div>
                            </div>
                            <div className="field-row">
                                <div className="row-half">
                                    <div className="date-field">
                                        
                                        <div className="field-label" >
                                            Start Date
                                        </div>
                                        <div>
                                            <FormControl
                                            sx={{width: {md: '100%', sm: '100%'}}}
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        
                                                        sx={{
                                                            width: '100%',
                                                            borderRadius: '8px',
                                                            
                                                            // mt: 2,
                                                            // mt: '6px',
                                                            // backgroundColor:"#F9F9F9",
                                                            // borderRadius:"1rem",
                                                            // "& fieldset": {
                                                            //     border: "1px solid #F9F9F9",
                                                            //     borderRadius:"1rem",  
                                                            //     borderRadius: '8px',   
                                                                
                                                            // },
                                                            "& .MuiOutlinedInput-root": {
                                                                borderRadius: '8px', 
                                                                border: "1px solid #F9F9F9"
                                                            }
                                                        }}
                                                        // inputFormat={
                                                        //     "dd/MM/yyyy"
                                                        // }
                                                        // value={dateExpected}
                                                        // label="select date"
                                                        name="feedbackExpectedBy"
                                                        // minDate={new Date()}
                                                        // maxDate={pmsCycleData?.peerAppraisalEndDate ?? null}
                                                        // onChange={(event) =>
                                                        //     {setDateExpected(event);setIsOpen(true);setDateErr(false)}
                                                        // }
                                                        renderInput={(params) => (
                                                            <TextField
                                                            size={"small"}
                                                            variant="outlined"
                                                            // helperText={dateErr && "Please select date"}
                                                            sx={{
                                                                pointerEvents: 'none',
                                                                '& .MuiOutlinedInput-root': {
                                                                'button': {
                                                                    pointerEvents: 'all',
                                                                }},
                                                                mt: '6px',
                                                                width: "100%",
                                                                "& fieldset": {
                                                                border: "1px solid #dedede",
                                                                },
                                                                "& .css-k4qjio-MuiFormHelperText-root": {
                                                                backgroundColor: "#ffffff",
                                                                margin: "0px",
                                                                paddingLeft: "0.5rem",
                                                                },
                                                                "& .MuiOutlinedInput-root": {
                                                                    border: "1px solid #F9F9F9",
                                                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                    borderRadius: '8px',
                                                                    'button': {
                                                                        pointerEvents: 'all',
                                                                    }
                                                                },
                                                            }}

                                                            {...params}
                                                            // error={dateErr}
                                                            // InputProps={{
                                                            //     ...params.InputProps,
                                                            //     endAdornment: (
                                                            //       <InputAdornment position="end">
                                                            //         <IconButton>
                                                            //           <CiCalendarDate 
                                                            //             onClick={() => params.inputRef.current?.focus()} // Ensure this triggers the date picker
                                                            //           /> {/* Replace this with any custom icon */}
                                                            //         </IconButton>
                                                            //       </InputAdornment>
                                                            //     ),
                                                            //   }}
                                                        />
                                                        )}
                                                    />                     
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>
                                    </div>

                                </div>
                                <div className="row-half">
                                    <div className="date-field">
                                        
                                        <div className="field-label" >
                                            End Date
                                        </div>
                                        <div>
                                            <FormControl
                                            sx={{width: {md: '100%', sm: '100%'}}}
                                            >
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        
                                                        sx={{
                                                            width: '100%',
                                                            borderRadius: '8px',
                                                            
                                                            // mt: 2,
                                                            // mt: '6px',
                                                            // backgroundColor:"#F9F9F9",
                                                            // borderRadius:"1rem",
                                                            // "& fieldset": {
                                                            //     border: "1px solid #F9F9F9",
                                                            //     borderRadius:"1rem",  
                                                            //     borderRadius: '8px',   
                                                                
                                                            // },
                                                            "& .MuiOutlinedInput-root": {
                                                                borderRadius: '8px', 
                                                                border: "1px solid #F9F9F9"
                                                            }
                                                        }}
                                                        // inputFormat={
                                                        //     "dd/MM/yyyy"
                                                        // }
                                                        // value={dateExpected}
                                                        // label="select date"
                                                        name="feedbackExpectedBy"
                                                        // minDate={new Date()}
                                                        // maxDate={pmsCycleData?.peerAppraisalEndDate ?? null}
                                                        // onChange={(event) =>
                                                        //     {setDateExpected(event);setIsOpen(true);setDateErr(false)}
                                                        // }
                                                        renderInput={(params) => (
                                                            <TextField
                                                            size={"small"}
                                                            variant="outlined"
                                                            // helperText={dateErr && "Please select date"}
                                                            sx={{
                                                                pointerEvents: 'none',
                                                                '& .MuiOutlinedInput-root': {
                                                                'button': {
                                                                    pointerEvents: 'all',
                                                                }},
                                                                mt: '6px',
                                                                width: "100%",
                                                                "& fieldset": {
                                                                border: "1px solid #dedede",
                                                                },
                                                                "& .css-k4qjio-MuiFormHelperText-root": {
                                                                backgroundColor: "#ffffff",
                                                                margin: "0px",
                                                                paddingLeft: "0.5rem",
                                                                },
                                                                "& .MuiOutlinedInput-root": {
                                                                    border: "1px solid #F9F9F9",
                                                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                    borderRadius: '8px',
                                                                    'button': {
                                                                        pointerEvents: 'all',
                                                                    }
                                                                },
                                                            }}

                                                            {...params}
                                                            // error={dateErr}
                                                            // InputProps={{
                                                            //     ...params.InputProps,
                                                            //     endAdornment: (
                                                            //       <InputAdornment position="end">
                                                            //         <IconButton>
                                                            //           <CiCalendarDate 
                                                            //             onClick={() => params.inputRef.current?.focus()} // Ensure this triggers the date picker
                                                            //           /> {/* Replace this with any custom icon */}
                                                            //         </IconButton>
                                                            //       </InputAdornment>
                                                            //     ),
                                                            //   }}
                                                        />
                                                        )}
                                                    />                     
                                                </LocalizationProvider>
                                            </FormControl>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="field-row">
                                <div className="row-half">
                                    <div className="select-field">
                                        
                                        <div className="field-label" >
                                            Updates on this Goal
                                        </div>
                                        <div>
                                            <TextField
                                                name="responseString"
                                                // value={
                                                //     vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.responseString
                                                // }
                                                // onChange={(event) =>
                                                //     handleChangeVertualPayload(
                                                //     event,
                                                //     "textField",
                                                //     tabIndex,
                                                //     tileIndex,
                                                //     nestedSubsectionI
                                                //     )
                                                // }
                                                id="outlined-multiline-static"
                                                // label="njknj"
                                                variant="outlined"
                                                // placeholder={`Provide your feedback here ${
                                                //     nestedSubsectionE.configObj
                                                //     .minCharacterLimit ||
                                                //     nestedSubsectionE.configObj
                                                //     .maxCharacterLimit
                                                //     ? `(limit: ${
                                                //         nestedSubsectionE
                                                //             .configObj
                                                //             .minCharacterLimit
                                                //             ? ` min: ${nestedSubsectionE.configObj.minCharacterLimit}`
                                                //             : ""
                                                //         }  ${
                                                //         nestedSubsectionE
                                                //             .configObj
                                                //             .maxCharacterLimit
                                                //             ? `max: ${nestedSubsectionE.configObj.maxCharacterLimit} `
                                                //             : ""
                                                //         }char)`
                                                //     : ""
                                                // }`}
                                                size="small"
                                                sx={{
                                                    width: '100%',
                                                    // borderRadius: '8px',
                                                    
                                                    // mt: 2,
                                                    mt: '6px',
                                                    // backgroundColor:"#F9F9F9",
                                                    // borderRadius:"1rem",
                                                    "& fieldset": {
                                                        // border: "1px solid #F9F9F9",
                                                        // borderRadius:"1rem",  
                                                        borderRadius: '8px',   
                                                        
                                                    },
                                                }}
                                                multiline
                                                // rows={3}
                                                minRows={3}
                                            />
                                        </div>

                                    </div>

                                </div>
                                <div className="row-half">
                                    <div className="select-field">
                                        
                                        <div className="field-label" >
                                            Path to green
                                        </div>
                                        <div>
                                            <TextField
                                                name="responseString"
                                                // value={
                                                //     vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.responseString
                                                // }
                                                // onChange={(event) =>
                                                //     handleChangeVertualPayload(
                                                //     event,
                                                //     "textField",
                                                //     tabIndex,
                                                //     tileIndex,
                                                //     nestedSubsectionI
                                                //     )
                                                // }
                                                id="outlined-multiline-static"
                                                // label="njknj"
                                                variant="outlined"
                                                // placeholder={`Provide your feedback here ${
                                                //     nestedSubsectionE.configObj
                                                //     .minCharacterLimit ||
                                                //     nestedSubsectionE.configObj
                                                //     .maxCharacterLimit
                                                //     ? `(limit: ${
                                                //         nestedSubsectionE
                                                //             .configObj
                                                //             .minCharacterLimit
                                                //             ? ` min: ${nestedSubsectionE.configObj.minCharacterLimit}`
                                                //             : ""
                                                //         }  ${
                                                //         nestedSubsectionE
                                                //             .configObj
                                                //             .maxCharacterLimit
                                                //             ? `max: ${nestedSubsectionE.configObj.maxCharacterLimit} `
                                                //             : ""
                                                //         }char)`
                                                //     : ""
                                                // }`}
                                                size="small"
                                                sx={{
                                                    width: '100%',
                                                    // borderRadius: '8px',
                                                    
                                                    // mt: 2,
                                                    mt: '6px',
                                                    // backgroundColor:"#F9F9F9",
                                                    // borderRadius:"1rem",
                                                    "& fieldset": {
                                                        // border: "1px solid #F9F9F9",
                                                        // borderRadius:"1rem",  
                                                        borderRadius: '8px',   
                                                        
                                                    },
                                                }}
                                                multiline
                                                // rows={3}
                                                minRows={3}
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                

                
                    <div className="form-container-group">
                        <div className="container-group-header">
                            <div className="group-header-title">
                                Milestone 1
                            </div>
                            <div className="group-header-divider-line">
                                
                            </div>
                            <div className="group-header-delete">

                            <div className="head-delete">
                                <div className="delete-icon">
                                    <MdDelete />
                                </div>
                                <div className="title">
                                    Delete this goal
                                </div>
                            </div>
                                
                            </div>
                        </div>

                        <div className="container-group-field">
                            <div className="group-field-line-left" />
                            <div className="group-field-margin">

                                <div className="form-group-contant">
                                    <div className="group-contant"> 

                                        <div key={'loop iterate'}>
                                            <div className="field-row">
                                                <div className="text-field">

                                                    <div className="field-label" >
                                                        Goal Title
                                                    </div>
                                                    <TextField
                                                        name="responseString"
                                                        id="outlined-multiline-static"
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{
                                                            width: '100%',
                                                            mt: '6px',
                                                            "& fieldset": {
                                                                borderRadius: '8px',                        
                                                                },
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="field-row">
                                                <div className="text-field">

                                                    <div className="field-label" >
                                                        Goal Description (0/1000 Characters)
                                                    </div>
                                                    <TextField
                                                        name="responseString"
                                                        id="outlined-multiline-static"
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{
                                                            width: '100%',
                                                            mt: '6px',
                                                            "& fieldset": {
                                                                borderRadius: '8px',     
                                                            },
                                                        }}
                                                        multiline
                                                        rows={0}
                                                    />
                                                </div>
                                            </div>

                                            <div className="field-row">
                                                <div className="row-half">
                                                    <div className="select-field">
                                                        
                                                        <div className="field-label" >
                                                            Add Stakeholders 
                                                        </div>
                                                        <div>
                                                            <FormControl
                                                            sx={{width: {md: '100%', sm: '100%'}}}
                                                            >
                                                            <Select

                                                                sx={{
                                                                    width: '100%',
                                                                    mt: '6px',
                                                                    "& fieldset": {
                                                                        borderRadius: '8px',   
                                                                        
                                                                    },
                                                                }}
                                                                size="small"
                                                                name='rating'
                                                                labelId='demo-simple-select-label'
                                                                id='demo-simple-select'
                                                            >
                                                            </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="selected-menu">
                                                            <div className="menu-item-container">
                                                                <div className="title">
                                                                    Micheal William

                                                                </div>
                                                                <div className="delete">
                                                                    <RxCross1 />
                                                                </div>
                                                            </div>
                                                            <div className="menu-item-container">
                                                                <div className="title">
                                                                    Wallace Mason

                                                                </div>
                                                                <div className="delete">
                                                                    <RxCross1 />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row-half">
                                                    <div className="select-field">
                                                        
                                                        <div className="field-label" >
                                                            Goal Cycle
                                                        </div>
                                                        <div>
                                                            <FormControl
                                                            sx={{width: {md: '100%', sm: '100%'}}}
                                                            >
                                                            <Select

                                                                sx={{
                                                                    width: '100%',
                                                                    mt: '6px',
                                                                    "& fieldset": {
                                                                        borderRadius: '8px',   
                                                                        
                                                                    },
                                                                }}
                                                                size="small"
                                                                name='rating'
                                                                labelId='demo-simple-select-label'
                                                                id='demo-simple-select'
                                                            >
                                                             
                                                            </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className="selected-menu">
                                                            <div className="menu-item-container">
                                                                <div className="title">
                                                                    Q1 cycle 2024

                                                                </div>
                                                                <div className="delete">
                                                                    <RxCross1 />
                                                                </div>
                                                            </div>
                                                            <div className="menu-item-container">
                                                                <div className="title">
                                                                    Q2 cycle 2024

                                                                </div>
                                                                <div className="delete">
                                                                    <RxCross1 />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="field-row">
                                                <div className="row-half">
                                                    <div className="select-field">
                                                        
                                                        <div className="field-label" >
                                                            Tag Goal
                                                        </div>
                                                        <div>
                                                            <FormControl
                                                            sx={{width: {md: '100%', sm: '100%'}}}
                                                            >
                                                            <Select

                                                                sx={{
                                                                    width: '100%',
                                                                    mt: '6px',
                                                                    "& fieldset": {
                                                                        borderRadius: '8px',   
                                                                        
                                                                    },
                                                                }}
                                                                size="small"
                                                                name='rating'
                                                                labelId='demo-simple-select-label'
                                                                id='demo-simple-select'
                                                            >
                                                            </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row-half">
                                                    <div className="select-field">
                                                        
                                                        <div className="field-label" >
                                                            Goal Weightage in %
                                                        </div>
                                                        <div>
                                                            <FormControl
                                                            sx={{width: {md: '100%', sm: '100%'}}}
                                                            >
                                                            <Select

                                                                sx={{
                                                                    width: '100%',
                                                                    mt: '6px',
                                                                    "& fieldset": {
                                                                        borderRadius: '8px',   
                                                                        
                                                                    },
                                                                }}
                                                                size="small"
                                                                name='rating'
                                                                labelId='demo-simple-select-label'
                                                                id='demo-simple-select'
                                                            >
                                                            </Select>
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="field-row">
                                                <div className="row-half">
                                                    <div className="date-field">
                                                        
                                                        <div className="field-label" >
                                                            Start Date
                                                        </div>
                                                        <div>
                                                            <FormControl
                                                            sx={{width: {md: '100%', sm: '100%'}}}
                                                            >
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DesktopDatePicker
                                                                        
                                                                        sx={{
                                                                            width: '100%',
                                                                            borderRadius: '8px',
                                                                            "& .MuiOutlinedInput-root": {
                                                                                borderRadius: '8px', 
                                                                                border: "1px solid #F9F9F9"
                                                                            }
                                                                        }}
                                                                        name="feedbackExpectedBy"
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                            size={"small"}
                                                                            variant="outlined"
                                                                            sx={{
                                                                                pointerEvents: 'none',
                                                                                '& .MuiOutlinedInput-root': {
                                                                                'button': {
                                                                                    pointerEvents: 'all',
                                                                                }},
                                                                                mt: '6px',
                                                                                width: "100%",
                                                                                "& fieldset": {
                                                                                border: "1px solid #dedede",
                                                                                },
                                                                                "& .css-k4qjio-MuiFormHelperText-root": {
                                                                                backgroundColor: "#ffffff",
                                                                                margin: "0px",
                                                                                paddingLeft: "0.5rem",
                                                                                },
                                                                                "& .MuiOutlinedInput-root": {
                                                                                    border: "1px solid #F9F9F9",
                                                                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                                    borderRadius: '8px',
                                                                                    'button': {
                                                                                        pointerEvents: 'all',
                                                                                    }
                                                                                },
                                                                            }}

                                                                            {...params}
                                                                        />
                                                                        )}
                                                                    />                     
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row-half">
                                                    <div className="date-field">
                                                        
                                                        <div className="field-label" >
                                                            End Date
                                                        </div>
                                                        <div>
                                                            <FormControl
                                                            sx={{width: {md: '100%', sm: '100%'}}}
                                                            >
                                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                    <DesktopDatePicker
                                                                        
                                                                        sx={{
                                                                            width: '100%',
                                                                            borderRadius: '8px',
                                                                            "& .MuiOutlinedInput-root": {
                                                                                borderRadius: '8px', 
                                                                                border: "1px solid #F9F9F9"
                                                                            }
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                            size={"small"}
                                                                            variant="outlined"
                                                                            sx={{
                                                                                pointerEvents: 'none',
                                                                                '& .MuiOutlinedInput-root': {
                                                                                'button': {
                                                                                    pointerEvents: 'all',
                                                                                }},
                                                                                mt: '6px',
                                                                                width: "100%",
                                                                                "& fieldset": {
                                                                                border: "1px solid #dedede",
                                                                                },
                                                                                "& .css-k4qjio-MuiFormHelperText-root": {
                                                                                backgroundColor: "#ffffff",
                                                                                margin: "0px",
                                                                                paddingLeft: "0.5rem",
                                                                                },
                                                                                "& .MuiOutlinedInput-root": {
                                                                                    border: "1px solid #F9F9F9",
                                                                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                                                                    borderRadius: '8px',
                                                                                    'button': {
                                                                                        pointerEvents: 'all',
                                                                                    }
                                                                                },
                                                                            }}

                                                                            {...params}
                                                                        />
                                                                        )}
                                                                    />                     
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="field-row">
                                                <div className="row-half">
                                                    <div className="select-field">
                                                        
                                                        <div className="field-label" >
                                                            Updates on this Goal
                                                        </div>
                                                        <div>
                                                            <TextField
                                                                name="responseString"
                                                                id="outlined-multiline-static"
                                                                variant="outlined"
                                                                size="small"
                                                                sx={{
                                                                    width: '100%',
                                                                    mt: '6px',
                                                                    "& fieldset": {  
                                                                        borderRadius: '8px',   
                                                                        
                                                                    },
                                                                }}
                                                                multiline
                                                                minRows={3}
                                                            />
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="row-half">
                                                    <div className="select-field">
                                                        
                                                        <div className="field-label" >
                                                            Path to green
                                                        </div>
                                                        <div>
                                                            <TextField
                                                                name="responseString"
                                                                // value={
                                                                //     vertualFormPayload[tabIndex].subSections[tileIndex].subSections[nestedSubsectionI].assessmentResponseObjectDTO.responseString
                                                                // }
                                                                // onChange={(event) =>
                                                                //     handleChangeVertualPayload(
                                                                //     event,
                                                                //     "textField",
                                                                //     tabIndex,
                                                                //     tileIndex,
                                                                //     nestedSubsectionI
                                                                //     )
                                                                // }
                                                                id="outlined-multiline-static"
                                                                // label="njknj"
                                                                variant="outlined"
                                                                // placeholder={`Provide your feedback here ${
                                                                //     nestedSubsectionE.configObj
                                                                //     .minCharacterLimit ||
                                                                //     nestedSubsectionE.configObj
                                                                //     .maxCharacterLimit
                                                                //     ? `(limit: ${
                                                                //         nestedSubsectionE
                                                                //             .configObj
                                                                //             .minCharacterLimit
                                                                //             ? ` min: ${nestedSubsectionE.configObj.minCharacterLimit}`
                                                                //             : ""
                                                                //         }  ${
                                                                //         nestedSubsectionE
                                                                //             .configObj
                                                                //             .maxCharacterLimit
                                                                //             ? `max: ${nestedSubsectionE.configObj.maxCharacterLimit} `
                                                                //             : ""
                                                                //         }char)`
                                                                //     : ""
                                                                // }`}
                                                                size="small"
                                                                sx={{
                                                                    width: '100%',
                                                                    // borderRadius: '8px',
                                                                    
                                                                    // mt: 2,
                                                                    mt: '6px',
                                                                    // backgroundColor:"#F9F9F9",
                                                                    // borderRadius:"1rem",
                                                                    "& fieldset": {
                                                                        // border: "1px solid #F9F9F9",
                                                                        // borderRadius:"1rem",  
                                                                        borderRadius: '8px',   
                                                                        
                                                                    },
                                                                }}
                                                                multiline
                                                                // rows={3}
                                                                minRows={3}
                                                            />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                            
                        </div>

                    </div>

                    <div className="d-flex justify-content-end gap-3 pt-5 main-btn">
                        {/* <div className="valuesBtn draft" style={styleSaveAsDraft()}>Auto Save 2 sec</div> */}
                        <button
                            style={styleSaveAsDraft()}
                            className="valuesBtn save-draft"
                            // onClick={() => handleSaveAsDraft('submitAsDraft')}
                        >
                            {submitLoader ? (
                            <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                            ) : (
                            // "Save as draft"
                            "Cancel"
                            )}
                        </button>


                        <button
                            style={styleSubmit()}
                            className="valuesBtn next"
                            onClick={() => handleValidate()}
                        >
                            {submitLoader ? (
                            <CircularProgress size={29} sx={{ color: "#ffffff" }} />
                            ) : (
                            "Submit"
                            )}
                        </button>
                    </div>

                    
            {showConfirmationPopup && 
            <DialogPopup openDialog={showConfirmationPopup} setOpenDialog={setShowConfirmationPopup} redirect={false} >
                <div className="feedback-dialog-wrap">
                <img src={checkIcon} className="checkIcon"></img>
                <div className="content">
                Are you sure you want to submit? Once submitted you will not be able to Edit or make any further changes. Please click on Confirm to proceed or Cancel to edit.
                {/* Thank you for completing the appraisal. Your feedback is important. Please note that once submitted, revisions will not be feasible. Click 'Confirm' if you intend to proceed. If you want to further edit or revise, click 'Cancel' */}
                </div>
                <div className="multi-btn" >
                <button className="button"
                    onClick={() => {
                        handleSubmitFeedback()
                    }}
                >Confirm</button>
                <button className="button"
                onClick={() => setShowConfirmationPopup(false)}
                >Cancel</button>
                </div>
                </div>
            </DialogPopup>}
        

                    

                </div>


            </div>

        </div>
    )
}

export default GoalsForm;
