import React from 'react';

const IDPPage = () => {
  return (
    <div>
      <h1>Individual Development Plan (IDP)</h1>
      <p>This is the IDP page content.</p>
    </div>
  );
};

export default IDPPage;