import React from 'react';

const EEPPage = () => {
  return (
    <div>
      <h1>Employee Engagement Program</h1>
      <p>This is the EEP page content.</p>
    </div>
  );
};

export default EEPPage;