import React from 'react';
import PropTypes from 'prop-types';
import { isEmptyNullUndefined } from '../../../utils/utils';

const EmpAssesmentStatic = ({data}) => {
    console.log('EmpAssesmentStatic data:', data);

    return (
        <div className="assessment-show-in-sidebar">
            <div className="main-heading">Employee Assessment</div>
            {data?.filter(section => section?.visible).map((section) => {
                console.log('Rendering section:', section);
                return (
                    <div key={section?.id} className="section-main">
                        <div className="section-label" dangerouslySetInnerHTML={{ __html: section?.label }} />
                        {section?.subSectionReferences?.filter(subSection => subSection?.visible).map((subSection) => {
                            console.log('Rendering subSection:', subSection);
                            return (
                                <div key={subSection?.id} className="sub-section-main">
                                    <div className="question-label" dangerouslySetInnerHTML={{ __html: subSection?.label }} />
                                    {subSection?.feedbacks && (
                                        // <div className='feedback-text'>{subSection?.feedbacks}</div>
                                        <div className='feedback-text'>
                                            {
                                                subSection?.feedbacks?.map((feedback, index) => {
                                                    return(
                                                        <div key={feedback.employeeId || index} className="emp-response">
                                                            <div className="text-responses">
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseString) && 
                                                                feedback?.responseObject?.responseString
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseBoolean) ? 
                                                                feedback?.responseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseInteger) && 
                                                                feedback?.responseObject?.responseInteger
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.rating) && 
                                                                feedback?.responseObject?.rating
                                                                }
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        feedback?.responseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                            
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    )
}

EmpAssesmentStatic.propTypes = {
    data: PropTypes.array,
};

export default EmpAssesmentStatic;