import React from 'react';
import PropTypes from 'prop-types';
import { isEmptyNullUndefined } from '../../../utils/utils';

const PeerFeedbackStatic = ({data}) => {

    console.log('dataasasasasasa', data);

    return (
        <div className="peer-feedback-container-in-sidebar">
            {data?.filter(section => section?.visible).map((section) => (
                <div key={section?.id} className="section-main">
                    <div className='section-label' dangerouslySetInnerHTML={{ __html: section?.label }} />
                    {section?.subSectionReferences?.filter(subSection => subSection?.visible).map((subSection) => (
                        <div key={subSection?.id} className="sub-section-main">
                            <div className="question-label" dangerouslySetInnerHTML={{ __html: subSection?.label }} />
                            {subSection?.feedbacks?.map((feedback) => (                                
                                <div key={feedback?.employeeId} className="feedback-item">
                                    <div className="feedback-header">
                                        <div className="employee-info">
                                            <span className="employee-name">{feedback?.employeeName}</span>
                                        </div>
                                        <div className="employee-department">
                                            <span className="employee-designation">{feedback?.employeeDesignation}</span>
                                        </div>
                                    </div>
                                    <div className="feedback-content">
                                    {subSection?.feedbacks && (
                                        <div className='feedback-text'>
                                            {
                                                subSection?.feedbacks?.map((feedback, index) => {
                                                    return(
                                                        <div key={feedback.employeeId || index} className="emp-response">
                                                            <div className="text-responses">
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseString) && 
                                                                feedback?.responseObject?.responseString
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseBoolean) ? 
                                                                feedback?.responseObject?.responseBoolean ? "Yes" : "No"
                                                                :
                                                                ""
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.responseInteger) && 
                                                                feedback?.responseObject?.responseInteger
                                                                }
                                                                {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.rating) && 
                                                                feedback?.responseObject?.rating
                                                                }
                                                            </div>
                                                            {
                                                                !isEmptyNullUndefined(feedback?.responseObject?.selection) && 
                                                                <div className="selections">
                                                                    {
                                                                        feedback?.responseObject?.selection?.map((selected, sIndex) => {
                                                                            return(
                                                                                <div className="selected-value">{selected?.name}</div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                            
                                        </div>
                                    )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default PeerFeedbackStatic;