import { Button, Checkbox, FormControl, InputAdornment, MenuItem, Select, Skeleton, TextField } from "@mui/material";
import { useState } from "react";
import Minus from "../../../../../assets/images/compensation/minus.svg";
import Plus from "../../../../../assets/images/compensation/plus.svg";
import GoalSummaryIcon from "../../../../../assets/images/goalView/goalSummary.svg";
import MilestoneSummaryIcon from "../../../../../assets/images/goalView/milestoneSummary.svg";
import QuIcon from "../../../../../assets/images/goalView/quIcon.svg";
import filterIcon from "../../../../../assets/images/filter.svg";
import { CurrencyFormat, getFormattedDate, isEmptyNullUndefined } from "../../../../utils/utils";
import { useSelector } from "react-redux";
import { IoIosSearch } from "react-icons/io";

const totalCompData = [
    {
        "id": "eaf41c58-1d25-46a1-ad06-b2a603d4f772",
        "employeeId": "5730d6b5-3cde-4bc0-9612-a4b832574547",
        "dateOfJoining": "2024-05-02",
        "name": "demo user 4",
        "emailId": "chetanuser4@yopmail.com",
        "jobCode": "OperationsMB -CL9PM",        
        "empCode": "21212",
        goalTitle: "Software Engineer",
        tag: "tag 1",
        approvalStatus: "Approved",
        goalsCompletion: "50%",
        goalStatus: "On track",
        startDate: new Date(),
       
    },
    {
        "id": "eaf41c58-1d25-46a1-ad06-b2a603d4f772",
        "employeeId": "5730d6b5-3cde-4bc0-9612-a4b832574547",
        "dateOfJoining": "2024-05-02",
        "name": "john doe",
        "emailId": "chetanuser4@yopmail.com",
        "jobCode": "OperationsMB -CL9PM",        
        "empCode": "21212",
        goalTitle: "Manager",
        tag: "tag 2",
        approvalStatus: "Approved",
        goalsCompletion: "30%",
        goalStatus: "On track",
        startDate: new Date(),
       
    },
    {
        "id": "eaf41c58-1d25-46a1-ad06-b2a603d4f772",
        "employeeId": "5730d6b5-3cde-4bc0-9612-a4b832574547",
        "dateOfJoining": "2024-05-02",
        "name": "demo user 4",
        "emailId": "chetanuser4@yopmail.com",
        "jobCode": "OperationsMB -CL9PM",        
        "empCode": "21212",
        goalTitle: "QA",
        tag: "tag 3",
        approvalStatus: "Approved",
        goalsCompletion: "70%",
        goalStatus: "On track",
        startDate: new Date(),
       
    },
  
]

const ViewGoalsRequest = () => {

    const columns = [
        "Goal Title",
        "Tag",
        "Approval status",
        "Goals Completion",
        "Goal Status",
        "Start Date",
    ]

    
    const currency = useSelector((state) => state?.empData?.empData?.company?.currency);
    const dateFormat = useSelector((state) => state?.empData?.empData?.company?.dateFormat);
    
    const [selectedYearDDReport, setSelectedYearDDReport] = useState('Q1 2024'); 
    const [selectedConfigColumns, setSelectedConfigColumns] = useState(columns);
    const [dataLoader, setDataLoader] = useState(false);
    const [activeTable, setActiveTable] = useState("Detail Goals");
 
 const goalYearList = [
        { id: '1', goalYearValue: 'Q1 2024' },
        { id: '2', goalYearValue: 'Q2 2024' },
        { id: '3', goalYearValue: 'Q3 2024' }
    ];

    const handleYearDDChange = (event) => {
        setSelectedYearDDReport(event.target.value);
    };

    const handleCollapse = (column) => {
        let temp = structuredClone(selectedConfigColumns);
        temp = temp?.filter((x) => x !== column);
        // } else {
        //     temp.push(column);
        // }
        setSelectedConfigColumns(temp);
    }

    return(
        <div className="view-goals-request">
                        {/*----- Year Drop Down -----*/}
            <div className="tabs-block">

                <div className="tab-d-flex">
                    <div className={`tab active`} >My Team Goals<span>{"03"}</span></div>
                    <div className={`tab`}>My Org Goals<span>{"02"}</span></div>
                    <div className={`tab`}>Shared Goals<span>{"02"}</span></div>
                </div>

                <div className="year-dropDown-div">
                    <FormControl className="year-dropDown">
                        <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedYearDDReport}
                            label="Q2 2024"
                            onChange={handleYearDDChange}
                            renderValue={(selected) => (
                                <span>{selected?.goalYearValue || "Q2 2024"}</span>
                            )}
                        >
                            {goalYearList?.map((goalYear) => {
                                return (
                                    <MenuItem value={goalYear}>{goalYear?.goalYearValue}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>

            </div>            
            

            {/*----- Chart layout -----*/}
            <div className="outer-chart-layout">
                {/*----- Goal Summary -----*/}
                <div className="column-layout">
                    <p className="goalMilestoneSummary">Goal Summary</p>

                    <div className="chart-column">
                        <img
                            src={GoalSummaryIcon}
                            alt="GoalSummaryIcon"
                        />

                        <div className="d-block-w100">
                            <div className="sub-outer-chart">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-green" />
                                        <p className="sub-col-value">4 <span className="sub-col-percent-value">(40%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Completed</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-yellow" />
                                        <p className="sub-col-value">30 <span className="sub-col-percent-value">(30%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Inprogress</p>
                                </div>
                            </div>

                            <div className="sub-outer-chart mt-18">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-blue" />
                                        <p className="sub-col-value">2 <span className="sub-col-percent-value">(20%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Not Started</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-red" />
                                        <p className="sub-col-value">1 <span className="sub-col-percent-value">(10%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Delayed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*----- Vertical Border -----*/}
                <div className="verticalBorder"></div>

                {/*----- Milestone Summary -----*/}
                <div className="column-layout">
                    <p className="goalMilestoneSummary">Milestone Summary</p>

                    <div className="chart-column">
                        <img
                            src={MilestoneSummaryIcon}
                            alt="MilestoneSummaryIcon"
                        />

                        <div className="d-block-w100">
                            <div className="sub-outer-chart">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-green" />
                                        <p className="sub-col-value">8 <span className="sub-col-percent-value">(40%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Completed</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-yellow" />
                                        <p className="sub-col-value">6 <span className="sub-col-percent-value">(30%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Inprogress</p>
                                </div>
                            </div>

                            <div className="sub-outer-chart mt-18">
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-blue" />
                                        <p className="sub-col-value">4 <span className="sub-col-percent-value">(20%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Not Started</p>
                                </div>
                                <div className="sub-outer-column">
                                    <div className="d-flex">
                                        <div className="status-card status-red" />
                                        <p className="sub-col-value">2 <span className="sub-col-percent-value">(10%)</span></p>
                                    </div>
                                    <p className="sub-col-status">Delayed</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tabs-block">

                <div className="tab-d-flex">
                    <button className={`tab ${activeTable === "Detail Goals" ? "active" : ""}`} onClick={() => setActiveTable("Detail Goals")}>Detail Goals<span>{"03"}</span></button>
                    <button className={`tab ${activeTable === "Team Summary" ? "active" : ""}`} onClick={() => setActiveTable("Team Summary")}>Team Summary<span>{"02"}</span></button>
                </div>

                <div className="btn-div">
                    <button className="excel-download">Export in Excel</button>
                    <button className="approve-goals">Approve Goals</button>
                </div>

            </div>       

            <div className="table-bg">
                <div className="table-top-layout">
                    {/*----- Search Bar -----*/}
                    <div className="table-top-column">
                        <TextField
                            id="input-with-icon-textfield"
                            placeholder="Search..."
                            // size="small"
                            fullWidth
                            className="search-bar"
                            // onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IoIosSearch />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                },
                            }}
                        />
                    </div>

                    {/*----- Table Fillter -----*/}
                    <div className="table-top-column">
                        <div className="table-filter-dropdown">
                            <button className="filter-tab" 
                            // onClick={() => setIsOpenFilter(true)}
                            >
                                <img src={filterIcon} alt="filter" />
                                Filter                                
                            </button>
                        </div>
                    </div>

                    {/*----- Gap -----*/}
                    <div className="table-top-column"></div>

                    {/*----- Expand All CheckBox -----*/}
                    <div className="table-top-column expand-all" >
                        <Checkbox
                            // checked={viewTableType === "Block planning View" ? blockColumns?.length === selectedConfigColumns?.length : columns?.length === selectedConfigColumns?.length}
                            // disabled={!selectedConfigColumns?.length === 0}
                            // onChange={() => setSelectedConfigColumns(viewTableType === "Block planning View" ? blockColumns : columns)}
                        />
                        Expand All
                    </div>
                </div>

            {
                activeTable === "Detail Goals" ? 
                <>
            <div className="goals-tab-btn-div">
                {/*----- Approved goals & Goals under review & Goals Under Draft Tab -----*/}
                <div className="goals-tab">
                    <div>
                        

                        {/*----- All and Selected Count Btn -----*/}
                        <div className="all-selected-count-border">
                            <Button className="all-select-btn">
                                All
                            </Button>
                            <Button className="all-select-btn active-btn">
                                Selected (1)
                            </Button>
                        </div>
                    </div>
                    <div className="qu-icon-div">
                        <img
                            src={QuIcon}
                            alt="QuIcon"
                            height={17}
                            width={17}
                        />
                    </div>
                </div>


                <div className="submit-Goals-div">
                    <Button variant="outlined" className="submit-Goals-btn">
                        Submit Goals
                    </Button>
                </div>
            </div>

            {
                (dataLoader) ?
                                   
                <table style={{padding: "1rem", marginTop: "2rem"}}>
                    <tbody>
                        {
                            [...Array(10).keys()].map((y) => {
                                return(
                                        <tr key={y}>
                                            {
                                                columns?.map((x) => {
                                                    return(
                                                        <td style={{padding: "0.5rem"}} key={x}>
                                                            <Skeleton width={100} />
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>

                    :
                    
                    isEmptyNullUndefined(totalCompData) ?
                    <div className="no-data">No data found</div>
                    :

                    <div className="table-container-sample">
                   
                        <table>
                        <thead>
                            
                        <tr>
                                <th className={`sticky`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Code</span>
                                    </div>    
                                </th>
                                <th className={`sticky2`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Name</span>
                                    </div>    
                                </th>
                            {
                                columns?.map((column,index) => {
                                    return(
                                            
                                                selectedConfigColumns?.includes(column) &&
                                                <th className={`${(column === "Emp Code") && "sticky"} ${((column === "Emp Name")) && "sticky2"}`}>
                                                    <div className="col-head-outer">
                                                    {((column === "Emp Code") || (column === "Emp Name")) ? "" : <img className="mr-5" src={!selectedConfigColumns?.includes(column) ? Plus : Minus} alt="Minus" onClick={() =>  handleCollapse(column)} />} 
                                                        <span className="table-heading">{!selectedConfigColumns?.includes(column) ? "" : column}</span>
                                                    </div>    
                                                </th>
                                            
                                    )
                                })
                            }

                            <th className="sticky-right">
                                <div className="col-head-outer">
                                    <span className="table-heading">Action</span>
                                </div>    
                            </th>
                            
                        </tr>
                        </thead>
                        {
                                            <tbody>
                                                {
                                                totalCompData.map((row) => (
                                                    <tr key={row?.empCode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    

                                                                <td className="table-value sticky" component="th" scope="row">{row?.empCode}</td>
                                                    { 
                                                                <td className="table-value-emp-name sticky2" align="left">
                                                                    {row?.name}&nbsp;                                                                   
                                                                </td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Goal Title") &&
                                                            <td className="table-value" align="left">{row?.goalTitle}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Tag") &&
                                                            <td className="table-value" align="left">{row?.tag}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Approval status") &&
                                                            <td className="table-value" align="left">{row?.approvalStatus}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Goals Completion") &&
                                                            <td className="table-value" align="left">{row?.goalsCompletion}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Goal Status") &&
                                                            <td className="table-value" align="left">{row?.goalStatus}</td>
                                                        }
                                                        {
                                                            selectedConfigColumns?.includes("Start Date") &&
                                                            <td className="table-value" align="left">{row?.startDate ? getFormattedDate(row?.startDate, dateFormat) : ""}</td>
                                                        }
                                                        {
                                                            <td className="table-value-btns sticky-right" align="left">
                                                                <button className="edit-btn" >Edit</button>
                                                            </td>
                                                        }
                                                        
                                                       
                                                    </tr>
                                                
                                                ))}
                                            </tbody>
                                    }
                        <tbody>
                            
                        </tbody>
                        </table>
                        </div>

            }
                </>
                :
                <>
                {
                (dataLoader) ?
                                   
                <table style={{padding: "1rem", marginTop: "2rem"}}>
                    <tbody>
                        {
                            [...Array(10).keys()].map((y) => {
                                return(
                                        <tr key={y}>
                                            {
                                                columns?.map((x) => {
                                                    return(
                                                        <td style={{padding: "0.5rem"}} key={x}>
                                                            <Skeleton width={100} />
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>

                    :
                    
                    isEmptyNullUndefined(totalCompData) ?
                    <div className="no-data">No data found</div>
                    :

                    <div className="table-container-sample">
                   
                        <table>
                        <thead>
                            
                        <tr>
                                <th className={`sticky`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Code</span>
                                    </div>    
                                </th>
                                <th className={`sticky2`}>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Emp Name</span>
                                    </div>    
                                </th>
                                <th>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Goals Under Draft</span>
                                    </div>    
                                </th>
                                <th>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Goals Submitted</span>
                                    </div>    
                                </th>
                                <th>
                                    <div className="col-head-outer">
                                        <span className="table-heading">Goals Approved</span>
                                    </div>    
                                </th>
                          
                            
                        </tr>
                        </thead>
                        {
                                            <tbody>
                                                {
                                                totalCompData.map((row) => (
                                                    <tr key={row?.empCode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    

                                                                <td className="table-value sticky" component="th" scope="row">{row?.empCode}</td>
                                                    { 
                                                                <td className="table-value-emp-name sticky2" align="left">
                                                                    {row?.name}&nbsp;                                                                   
                                                                </td>
                                                        }
                                                        
                                                            <td className="table-value" align="left">{row?.goalTitle}</td>
                                                        
                                                            <td className="table-value" align="left">{row?.tag}</td>
                                                        
                                                            <td className="table-value" align="left">{row?.approvalStatus}</td>
                                                       
                                                        
                                                       
                                                    </tr>
                                                
                                                ))}
                                            </tbody>
                                    }
                        <tbody>
                            
                        </tbody>
                        </table>
                        </div>

            }
                </>
            }     


        </div>
        </div>
    )
}

export default ViewGoalsRequest;

